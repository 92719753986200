import React from 'react'

const McCarthyInfo = (
    <>
    <p>Name:  MCCARTHY, Michael<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  White Bird Canyon, Idaho, 17 June 1877<br />
Entered Service:  New York, New York<br />
Date of Birth:  19 April 1845<br />
Place of Birth:  St. Johns, Newfoundland, Canada<br />
Current Location:  Mountain View Cemetery, (IOOF Section, Block P, Lot 18, Grave 5), Walla Walla, Washington  (Died 15 January 1914)</p>

<p>Citation:  Was detailed with six men to hold a commanding position and held it with great gallantry until the troops fell back.  He then fought his way through the Indians, rejoined a portion of his command, and continued the fight in retreat.  He had two horses shot from under him and was captured, but escaped and reported for duty after three days hiding and wandering in the mountains.</p>

<p>Notes:  At one time, McCarthy went by the name Augustus Howard.  History miscrecorded his date of action as June 1876-January 1877.  The letters in his Medal of Honor file obtained from the National Archives very clearly state that his action took place on June 17, 1877, during the battle at White Bird Canyon, Idaho.  McCarthy also kept a diary which recorded his actions as June 1877.  The Army issued a Records correction in 2012. Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/3rdQtr2001.pdf" target="_blank" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/3rdQtr2001.pdf</a>

</p>
    </>
)

export default McCarthyInfo