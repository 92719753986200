import React from 'react'

const LittletonInfo = (
    <>
    <p>Name:  LITTLETON, Herbert A<br />
Service:  U.S. Marine Corps<br />
Conflict:  Korean War<br />
Location and Date of Act:  Chungchon, Korea, 22 April 1951<br />
Entered Service:  Nampa, Idaho<br />
Date of Birth:  1 July 1930<br />
Place of Birth:  Mena, Arkansas<br />
Current Location:  Kohler Lawn Cemetery (Section R, Lot 388, Grave 3-1), Nampa, Idaho  (KIA 22 April 1951)
</p>

<p>Citation:  For conspicuous gallantry and intrepidity at the risk of his life above and beyond the call of duty while serving as a Radio Operator with an Artillery Forward Observation Team of Company C, First Battalion, Seventh Marines, First Marine Division (Reinforced), in action against enemy aggressor forces in Korea on 22 April 1951.  Standing watch when a well-concealed and numerically superior enemy force launched a violent night attack from near-by positions against his company, Private First Class Littleton quickly alerted the forward observation team and immediately moved into an advantageous position to assist in calling down artillery fire on the hostile force.  When an enemy hand grenade was thrown into his vantage point shortly after the arrival of the remainder of the team, he unhesitatingly hurled himself on the deadly missile, absorbing its full, shattering impact in his own body.  By his prompt action and heroic spirit of self-sacrifice, he saved the other members of his team from serious injury or death and enabled them to carry on the vital mission which culminated in the repulse of the hostile attack.  His indomitable valor in the face of almost certain death reflects the highest credit upon Private First Class Littleton and the United States Naval Service.  He gallantly gave his life for his country.</p>

<p>Notes:  Littleton’s name is Herbert A Littleton; he does not have a middle name.  His medal was originally accredited to South Dakota but in 1965 the family contacted Idaho’s Senator Church and the record was officially corrected changing it to Idaho.
Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/1stQtr2002.pdf" target="_blank" rel="noopner noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/1stQtr2002.pdf</a>
</p>
    </>
)

export default LittletonInfo