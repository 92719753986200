import React from 'react'

const ReasonerInfo = (
    <>
    <p>Name:  REASONER, Frank Stanley<br />
Service:  U.S. Marine Corps<br />
Conflict:  Vietnam<br />
Location and Date of Act:  Near Da Nang, Vietnam, 12 July 1965<br />
Entered Service:  Kellogg, Idaho<br />
Date of Birth:  16 September 1937<br />
Place of Birth:  Spokane, Washington<br />
Current Location:  Greenwood Cemetery, Kellogg, Idaho, (Veterans Section, Row 7, Lot 346), (KIA 12 Jul 1965)
</p>

<p>Citation:  For conspicuous gallantry and intrepidity at the risk of his life above and beyond the call of duty.  The reconnaissance patrol led by First Lieutenant Reasoner had deeply penetrated heavily controlled enemy territory when it came under extremely heavy fire from an estimated 50 to 100 Viet Cong insurgents.  Accompanying the advance party and the point that consisted of five men, he immediately deployed his men for an assault after the Viet Cong had opened fire from numerous concealed positions.  Boldly shouting encouragement, and virtually isolated from the main body, he organized a base of fire for an assault on the enemy positions.  The slashing fury of the Viet Cong machine-gun and automatic weapons fire made it impossible for the main body to move forward.  Repeatedly exposing himself to the devastating attack he skillfully provided covering fire, killing at least two Viet Cong and effectively silencing an automatic-weapons position in a valiant attempt to effect evacuation of a wounded man.  As casualties began to mount his radio operator was wounded and First Lieutenant Reasoner immediately moved to his side and tended his wounds.  When the radio operator was hit a second time while attempting to reach a covered position, First Lieutenant Reasoner courageously running to his aid through the grazing machine-gun fire fell mortally wounded.  His indomitable fighting spirit, valiant leadership and unflinching devotion to duty provided the inspiration that was to enable the patrol to complete its mission without further casualties.  In the face of almost certain death he gallantly gave his life in the service of his country.  His actions upheld the highest traditions of the Marine Corps and the U.S. Naval Service.</p>

<p>Notes:  Reasoner received a special Congressional Appointment to West Point from Idaho Senator Henry C. Dworshak and graduated in the class of 1962.</p>
    </>
)

export default ReasonerInfo