import React from 'react'
import { Link } from 'gatsby'

const IdahoArmyHistoryToC = () => (
    <>
    <p><Link to="/history#321st-engineer">321st Engineer Engineer Battalion (Army Reserve) Boise, Idaho</Link></p>
    <p><Link to="/history#air-cadet-training-school">CAA (now called FAA) Air Cadet Training School (1943-1944) Coeur d'Alene, Idaho</Link></p>
    <p><Link to="/history#army-astu-school">U.S. Army ASTU School (1943-1944) University of Idaho, Moscow, Idaho</Link></p>
    <p><Link to="/history#us-army-forts">U.S. Army Forts</Link></p>
    </>
)

export default IdahoArmyHistoryToC