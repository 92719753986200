import React from 'react'
import {Container, Row, Col, Table, Tab } from 'react-bootstrap'
import MedalofHonorTable from './MedalofHonorTable'

const IdahoCMoHRecipients = () => (
    <>
  
    <MedalofHonorTable />

    </>
)

export default IdahoCMoHRecipients;