import React from 'react'

const SchoonoverInfo = (
    <>
    <p>Name:  SCHOONOVER, Dan D.<br />
Service:  U.S. Army<br />
Conflict:  Korean War<br />
Location and Date of Act:  Near Sokkogae, Korea, 8-10 July 1953<br />
Entered Service:  Boise, Idaho<br />
Date of Birth:  8 October 1933<br />
Place of Birth:  Boise, Idaho<br />
Current Location:  Missing in Action.  An ‘In Memory Of’ Cenotaph has been erected in his honor in Field of Honor 2, Row 16, Lot 50A, Morris Hill Cemetery, Boise, Idaho.</p>

<p>Citation:  Corporal Schoonover, distinguished himself by conspicuous gallantry and outstanding courage above and beyond the call of duty in action against the enemy.  He was in charge of an engineer demolition squad attached to an infantry company which was committed to dislodge the enemy from a vital hill.  Realizing that the heavy fighting and intense enemy fire made it impossible to carry out his mission, he voluntarily employed his unit as a rifle squad and, forging up the steep barren slope, participated in the assault on hostile positions.  When an artillery round exploded on the roof of an enemy bunker, he courageously ran forward and leaped into the position, killing one hostile infantryman and taking another prisoner.  Later in the action, when friendly forces were pinned down by vicious fire from another enemy bunker, he dashed through the hail of fire, hurled grenades in the nearest aperture, then ran to the doorway and emptied his pistol, killing the remainder of the enemy.  His brave action neutralized the position and enabled friendly troops to continue their advance to the crest of the hill.  When the enemy counterattacked he constantly exposed himself to the heavy bombardment to direct the fire of his men and to call in an effective artillery barrage on hostile forces.  Although the company was relieved early the following morning, he voluntarily remained in the area, manned a machinegun for several hours, and subsequently joined another assault on enemy emplacements.  When last seen he was operating an automatic rifle with devastating effect until mortally wounded by artillery fire.  Corporal Schoonover’s heroic leadership during two days of heavy fighting, superb personal bravery, and willing self-sacrifice inspired his comrades and saved many lives, reflecting lasting glory upon himself and upholding the honored traditions of the military service.</p>

<p>Notes:  Schoonover's middle name has been listed as both Dwain and Dwayne.</p>
    </>
)

export default SchoonoverInfo