import React from 'react'

const GaryInfo = (
    <>
    <p>
    Name: GARY, Donald Arthur<br />
    Service: U.S. Navy<br />
    Conflict: WWII<br />
    Location and Date of Act: near Kobe, Japan, 19 March 1945<br />
    Entered Service: Cincinnati, Ohio<br />
    Date of Birth: 23 July 1903<br />
    Place of Birth: Findlay, Ohio<br />
    Current Location:  Fort Rosecrans National Cemetery (Section A1, Grave 3B), San Diego, California  (Died 9 April 1977).</p>
    <p>Citation: For conspicuous gallantry and intrepidity at the risk of his life above and beyond the call of duty as an engineering officer attached to the U.S.S. Franklin when that vessel was fiercely attacked by enemy aircraft during the operations against the Japanese Home Islands near Kobe, Japan, 19 March 1945.  Stationed on the third deck when the ship was rocked by a series of violent explosions set off in her own ready bombs, rockets, and ammunition by the hostile attack, Lt. (j.g.) Gary unhesitatingly risked his life to assist several hundred men trapped in a messing compartment filled with smoke, and with no apparent egress.  As the imperiled men below decks became increasingly panic stricken under the raging fury of incessant explosions, he confidently assured them he would find a means of effecting their release and, groping through the dark, debris-filled corridors, ultimately discovered an escapeway.  Stanchly determined, he struggled back to the messing compartment 3 times despite menacing flames, flooding water, and the ominous threat of sudden additional explosions, on each occasion calmly leading his men through the blanketing pall of smoke until the last one had been saved.  Selfless in his concern for his ship and his fellows, he constantly rallied others about him, repeatedly organized and led fire-fighting parties into the blazing inferno on the flight deck and, when firerooms 1 and 2 were found to be inoperable, entered the No. 3 fireroom and directed the raising of steam in 1 boiler in the face of extreme difficulty and hazard.  An inspiring and courageous leader, Lt. (j.g.) Gary rendered self-sacrificing service under the most perilous conditions and, by his heroic initiative, fortitude, and valor, was responsible for the saving of several hundred lives.  His conduct throughout reflects the highest credit upon himself and upon the U.S. Naval Service.</p>
    <p>Notes: Gary transferred to the USS Idaho on 7 September 1928.  He was discharged at San Pedro, Calif., on 14 February 1930, but reenlisted and served his next period of reenlistment as a chief watertender on board the USS Idaho.</p>
    </>
)

export default GaryInfo