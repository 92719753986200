import React from 'react'

const HammerbergInfo = (
    <>
    <p>Name:  HAMMERBERG, Owen Francis Patrick<br />
Service:  U.S. Navy<br />
Conflict:  WWII<br />
Location and Date of Act:  Pearl Harbor, Oahu, Territory of Hawaii, 17 February 1945<br />
Entered Service:  Detroit, Michigan<br />
Date of Birth: 31 May 1920<br />
Place of Birth:  Daggett, Michigan<br />
Current Location: Cemetery: Holy Sepulchre Cemetery (Section 4, Lot 145, Grave 1), Southfield, Michigan  (Died 17 February 1945)</p>
<p>Citation: For conspicuous gallantry and intrepidity at the risk of his life above and beyond the call of duty as a diver engaged in rescue operations at West Loch, Pearl Harbor, 17 February 1945.  Aware of the danger when 2 fellow divers were hopelessly trapped in a cave-in of steel wreckage while tunneling with jet nozzles under an LST sunk in 40 feet of water and 20 feet of mud, Hammerberg unhesitatingly went overboard in a valiant attempt to effect their rescue despite the certain hazard of additional cave-ins and the risk of fouling his lifeline on jagged pieces of steel imbedded in the shifting mud.  Washing a passage through the original excavation, he reached the first of the trapped men, freed him from the wreckage and, working desperately in pitch-black darkness, finally effected his release from fouled lines, thereby enabling him to reach the surface.  Wearied but undaunted after several hours of arduous labor, Hammerberg resolved to continue his struggle to wash through the oozing submarine, subterranean mud in a determined effort to save the second diver.  Venturing still farther under the buried hulk, he held tenaciously to his purpose, reaching a place immediately above the other man just as another cave-in occurred and a heavy piece of steel pinned him crosswise over his shipmate in a position which protected the man beneath from further injury while placing the full brunt of terrific pressure on himself.  Although he succumbed in agony 18 hours after he had gone to the aid of his fellow divers, Hammerberg, by his cool judgment, unfaltering professional skill and consistent disregard of all personal danger in the face of tremendous odds, had contributed effectively to the saving of his 2 comrades.  His heroic spirit of self-sacrifice throughout enhanced and sustained the highest traditions of the U.S. Naval Service.  He gallantly gave his life in the service of his country.</p>
<p>Notes:  Although Hammerberg has no known ties to Idaho, the first ship he served on after his July 1941 enlistment was the USS Idaho.  He served aboard her until June 1942.</p>
    </>
)

export default HammerbergInfo