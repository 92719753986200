import React from 'react'

const NakamuraInfo = (
    <>
    <p>Name:  NAKAMURA, William Kenzo<br />
Service:  U.S. Army<br />
Conflict:  WWII<br />
Location and Date of Act:  Castellina Italy, 4 July 1944<br />
Entered Service:  Hunt, Idaho<br />
Date of Birth:  21 January 1922<br />
Place of Birth:  Seattle, Washington<br />
Current Location:  Evergreen-Washelli Cemetery (Washelli Veterans Section Lexington W Grave 28), Seattle, Washington  (KIA 4 July 1944)</p>

<p>Citation:  Private First Class William K. Nakamura distinguished himself by extraordinary heroism in action on 4 July 1944, near Castellina, Italy.  During a fierce firefight, Private First Class Nakamura’s platoon became pinned down by enemy machine gun fire from a concealed position.  On his own initiative, Private First Class Nakamura crawled 20 yards toward the hostile nest with fire from the enemy machine gun barely missing him.  Reaching a point 15 yards from the position, he quickly raised himself to a kneeling position and threw four hand grenades, killing or wounding at least three of the enemy soldiers.  The enemy weapon silenced, Private First Class Nakamura crawled back to his platoon, which was able to continue its advance as a result of his courageous action.  Later, his company was ordered to withdraw from the crest of a hill so that a mortar barrage could be placed on the ridge.  On his own initiative, Private First Class Nakamura remained in position to cover his comrades’ withdrawal.  While moving toward the safety of a wooded draw, his platoon became pinned down by deadly machine gun fire.  Crawling to a point from which he could fire on the enemy position, Private First Class Nakamura quickly and accurately fired his weapon to pin down the enemy machine gunners.  His platoon was then able to withdraw to safety without further casualties.  Private First Class Nakamura was killed during this heroic stand.  Private First Class Nakamura’s extraordinary heroism and devotion to duty are in keeping with the highest traditions of military service and reflect great credit on him, his unit, and the United States Army.</p>

<p>Notes:  Nakamura entered the service from the Minidoka Relocation Center, located near Twin Falls, Idaho, one of 10 Japanese American relocation camps built after the 7 December 1941 bombing of Pearl Harbor.  In January 1943, after the War Department announced that Americans of Japanese descent would be allowed to enter the military, William and his brother became one of over 300 volunteers to join from Minidoka.  When William was killed in action, he was the first casualty from Minidoka.  Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/2ndQtr2001.pdf" target="_blnak" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/2ndQtr2001.pdf</a></p>
    </>
)

export default NakamuraInfo