import React from 'react'

const HeiseInfo = (
    <>
    <p>Name:  HEISE, Richard Clamor<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  vicinity of the Black Mountains of Arizona, 13 August through 31 October 1868<br />
Entered Service:  New York, New York<br />
Date of Birth:  12 December 1844<br />
Place of Birth:  Germany<br />
Current Location:  Heise Hot Springs Cemetery, Heise, Idaho  (Died October 31, 1921)</p>
<p>Citation:  Bravery in scouts and actions against Indians.</p>
<p>Notes:  Heise was also the Founder of Heise Hot Springs, Heise, Idaho which is still in operation.  Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/4thQtr2004.pdf" target="_blank" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/4thQtr2004.pdf</a>.</p>
    </>
)

export default HeiseInfo