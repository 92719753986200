import React from 'react'

const BleakInfo = (
    <>
    <p>
    Name:  BLEAK, David Bruce<br />
Service:  U.S. Army<br />
Conflict:  Korean War<br />
Location and Date of Act:  Vicinity of Minari-gol, Korea, 14 June 1952<br />
Entered Service:  Shelley, Idaho<br />
Date of Birth:  27 February 1932<br />
Place of Birth:  Idaho Falls, Idaho<br />
Current Location:  David Bleak passed away on 23 March 2006; his Cremains were scattered at a favorite Idaho fishing location.  His family placed an 'In Memory Of' marker in the Lost River Cemetery (Section D, Row 2, Space 1), Butte County, Idaho.</p>
<p>Citation:  Sergeant Bleak, a member of the medical company, distinguished himself by conspicuous gallantry and indomitable courage above and beyond the call of duty in action against the enemy.  As a medical aidman, he volunteered to accompany a reconnaissance patrol committed to engage the enemy and capture a prisoner for interrogation.  Forging up the rugged slope of the key terrain, the group was subjected to intense automatic weapons and small arms fire and suffered several casualties.  After administering to the wounded, he continued to advance with the patrol.  Nearing the military crest of the hill, while attempting to cross the fire swept area to attend the wounded, he came under hostile fire from a small group of the enemy concealed in a trench.  Entering the trench, he closed with the enemy, killed two with bare hands and a third with his trench knife.  Moving from the emplacement, he saw a concussion grenade fall in front of a companion and, quickly shifting his position, shielded the man from the impact of the blast.  Later, while ministering to the wounded, he was struck by a hostile bullet but, despite the wound, he undertook to evacuate a wounded comrade.  As he moved down the hill with his heavy burden, he was attacked by two enemy soldiers with fixed bayonets.  Closing with the aggressors, he grabbed them and smashed their heads together, then carried his helpless comrade down the hill to safety.  Sergeant Bleak’s dauntless courage and intrepid actions reflect utmost credit upon himself and are in keeping with the honored traditions of the military service.</p>
<p>Notes:  On June 14, 2006, David Bleak's Medal of Honor was presented to the Idaho Military Historical Society by his family for display in the Idaho Military History Museum, Boise, Idaho.  His Medal was unveiled on June 14, 2007 and is now on exhibit.  Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/3rdQtr2000.pdf" target="_blank" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/3rdQtr2000.pdf</a></p>
    </>
)

export default BleakInfo