import React from 'react'

const NorrisInfo = (
    <>
    <p>Name:  NORRIS, Thomas Rolland<br />
Service:  U.S. Navy<br />
Conflict:  Vietnam<br />
Location and Date of Act:  Quang Tri Province, Viet Nam, 10-13 April 1972<br />
Entered Service:  Silver Spring, Maryland<br />
Date of Birth:  14 January 1944<br />
Place of Birth:  Jacksonville, Florida<br />
Current Location:  Lives in Idaho</p>

<p>Citation:  For conspicuous gallantry and intrepidity in action at the risk of his life above and beyond the call of duty while serving as a SEAL Advisor with the Strategic Technical Directorate Assistance Team, Headquarters, U.S. Military Assistance Command, Vietnam.  During the period 10 to 13 April 1972, Lieutenant Norris completed an unprecedented ground rescue of two downed pilots deep within heavily controlled enemy territory in Quang Tri Province.  Lieutenant Norris, on the night of 10 April, led a five-man patrol through 2,000 meters of heavily controlled enemy territory, located one of the downed pilots at daybreak, and returned to the Forward Operating Base (FOB).  On 11 April, after a devastating mortar and rocket attack on the small FOB, Lieutenant Norris led a three man team on two unsuccessful rescue attempts for the second pilot.  On the afternoon of the 12th, a Forward Air Controller located the pilot and notified Lieutenant Norris.  Dressed in fishermen disguises and using a sampan, Lieutenant Norris and one Vietnamese traveled throughout that night and found the injured pilot at dawn.  Covering the pilot with bamboo and vegetation, they began the return journey, successfully evading a North Vietnamese patrol.  Approaching the FOB, they came under heavy machine gun fire.  Lieutenant Norris called in an air strike which provided suppression fire and a smoke screen, allowing the rescue party to reach the FOB.  By his outstanding display of decisive leadership, undaunted courage, and selfless dedication in the face of extreme danger, Lieutenant Norris enhanced the finest traditions of the United States Naval Service.</p>

<p>Notes:  Six months after his MoH action, Norris was on another mission when he was grievously wounded.  One of his team members, Engineman First Class Michael Thornton, in what became a grueling 2-hour operation, rescued Norris and kept him afloat until they could be rescued.  For this action, Engineman Thornton was awarded the Medal of Honor in October 1973.  Norris was medically retired after this but went on to serve as an FBI agent for 20 years.  He was an original member of the FBI's Hostage Rescue Team as an assault team leader.
As part of the Portraits of Valor book, Mr. Norris gave an interview about his MoH action.  Click here to watch it: <a href="https://www.youtube.com/watch?v=gS57YKGHDKc" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=gS57YKGHDKc</a></p>
<p>
As part of the Portraits of Valor book, Mr. Thornton gave an interview about his MoH action, the rescue of Mr. Norris. Click here to watch it: <a href="www.youtube.com/watch?v=Tc1KrzKavnsk" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=Tc1KrzKavnsk</a></p>

<p>The two of them gave a joint interview in 2016.  Click here to watch it: <a href="https://www.youtube.com/watch?v=CXwP2qr4Qwo" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=CXwP2qr4Qwo (Runs about an hour and a half)</a></p>
    </>
)

export default NorrisInfo