import React, {useState} from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Container, Row, Col } from "react-bootstrap"

import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import HistorySidebar from "../components/HistoryPage/HistorySidebar"

import IdahoCMoHRecpients from '../components/HistoryPage/IdahoCMoHRecipients'
import IdahoNavalHistoryToC from "../components/HistoryPage/IdahoNavalHistoryToC"
import IdahoArmyNationalGuardToC from "../components/HistoryPage/IdahoArmyNationalGuardToC"
import IdahoArmyHistoryToC from "../components/HistoryPage/IdahoArmyHistoryToC"
import IdahoAirForceHistoryToC from "../components/HistoryPage/IdahoAirForceHistoryToC"
import IdahoMarineCorpsHistoryToC from "../components/HistoryPage/IdahoMarineCorpsHistoryToC"

import IdahoNavyHistory from "../components/HistoryPage/NavyHistory/IdahoNavyHistory"

import BackToTop from "../components/HistoryPage/BackToTop"

const History = ({ data }) => {
  //const page = data.historySections.edges[0].node
  const ussIdahoShipsSection = data.ussIdahoShips.edges[0].node
  const idahoNavalHistorySection = data.idahoNavalHistory.edges[0].node
  const idahoNationalGuardSection = data.idahoNationalGuard.edges[0].node
  const idahoArmyHistorySection = data.idahoArmyHistory.edges[0].node
  const idahoAirForceHistorySection = data.idahoAirForceHistory.edges[0].node
  const idahoMarineCorpsHistorySection = data.idahoMarineCorpsHistory.edges[0].node
  const idahoSubmarinersOnEternalPatrolSection = data.idahoSubmarinersOnEternalPatrol.edges[0].node
  const idahoCongressionalMedalOfHonorRecipientsSection = data.idahoCongressionalMedalOfHonorRecipients.edges[0].node
  const nativeAmericanWarriorsSection = data.nativeAmericanWarriors.edges[0].node
  const submarineDocumentariesSection = data.submarineDocumentaries.edges[0].node
  const idahoMilitaryHistoryDocumentariesBooksSection = data.idahoMilitaryHistoryDocumentariesBooks.edges[0].node
  const usNavyShipsNamedForIdaho = data.usNavyShipsNamedForIdaho.edges[0].node
  

console.log(data)

const columnsOptions = {
  renderNode: {
              
              [INLINES.EMBEDDED_ENTRY]: node => {
                const embed  = node.data.target.fields.leftColumnLongText
                console.log("Embed" + embed['en-US'])

                return (
                  <>
                      {/* {embed.leftColumnLongText["en-US"]} */}
                      
                        dangerouslySetInnerHTML={{
                          __html: `${embed['en-US']}`,
                        }}
                  </>
                )
            }
  }
}

const options = {
  renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({data: { target: { fields }}}) => 
          <div
          dangerouslySetInnerHTML={{
            __html: `<div style="width: auto; float: right; clear: both; margin-left: 20px; ">
            <img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="img-fluid" style="margin: 0px" />
            <p style="text-align: center; max-width: 320px;">
              <em>${fields.title["en-US"]}</em>
            </p>
            </div>`,
          }}
        />,

          [BLOCKS.EMBEDDED_ENTRY]: (node) => {
              const fields = node.data.target.fields.imageLink;
              const imgLnk = node.data.target.fields.url;
              console.log(fields, imgLnk)
                  return (
                  <div dangerouslySetInnerHTML={{__html: `<a href="${imgLnk['en-US']}"><img class="img-fluid" src="${fields['en-US'].fields.file['en-US'].url}" alt="${fields.title}" style="width: 100%" /></a>` }} />
                  )
              },

              [INLINES.EMBEDDED_ENTRY]: (node) => {
                const embed = node.data.target.fields.markdownContent;
                console.log('embed data: ' + embed['en-US'])
                return (
                    <div dangerouslySetInnerHTML={{__html: `${embed['en-US']}`}} />
                )
            },
            [INLINES.HYPERLINK]: (node) => {
              return <a href={node.data.uri} target={`${node.data.uri.startsWith('https://uss-idaho.inlightmedia.io/') ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith('https://uss-idaho.inlightmedia.io/') ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }

  }
}

  return (
    <Layout>
      <SEO title="History" />
      <Container fluid className="container-fluid-full">
        <PageTitle subPage="History" pageTitle="History" link="/">
          {/* {page.pageTitle} */}
        </PageTitle>
        <ContentBlock>
          <Row>
          <Col lg={12} xl={{span: 3, order: 2}} className="mb-4">
             <HistorySidebar />
            </Col>
            <BackToTop 
            scrollWhere="History Menu"
          />
            <Col lg={12} xl={{span: 9, order: 1}}>
              <div id="uss-idaho-ships" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>USS Idaho Ships</h1>
                
                <p className="blog-text">
                  {documentToReactComponents(
                    ussIdahoShipsSection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json,
                    options
                  )}
                </p>
              </div>
              <hr />

              <IdahoNavyHistory />
              {/* <div id="idaho-naval-history" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Idaho Naval History Brief Summaries</h1>
                <h3>Idaho has a rich Navy military history. Click the link to go directly to the article.</h3>
                <IdahoNavalHistoryToC />
                  {documentToReactComponents(
                    idahoNavalHistorySection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json,
                    options
                  )}
              </div> */}
              <hr />
               
              <div id="idaho-national-guard" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Idaho National Guard</h1>
                <IdahoArmyNationalGuardToC />
                <p className="blog-text">
                  {documentToReactComponents(
                      idahoNationalGuardSection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json, options)}
                </p>
              </div>
              <hr />
              <div id="idaho-army-history" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Idaho Army Forces</h1>
                <p className="blog-text">
                  <IdahoArmyHistoryToC />
                  {documentToReactComponents(
                      idahoArmyHistorySection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json, options)}
                </p>
              </div>
              <hr />

              <div id="idaho-air-force-history" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Idaho Air Force History</h1>
                <p className="blog-text">
                  <IdahoAirForceHistoryToC />
                  {documentToReactComponents(
                      idahoAirForceHistorySection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json, options)}
                </p>
              </div>
              <hr />

              <div id="idaho-marine-corps-history" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Idaho Marine Corps History</h1>
                <p className="blog-text">
                  <IdahoMarineCorpsHistoryToC />
                  {documentToReactComponents(
                      idahoMarineCorpsHistorySection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json,
                      options)}
                </p>
              </div>
              <hr />

              <div id="idaho-submariners-on-eternal-patrol" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Idaho Submariners on Eternal Patrol</h1>
                <p className="blog-text">
                  {documentToReactComponents(
                      idahoSubmarinersOnEternalPatrolSection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json, options)}
                </p>
              </div>
              <hr />

              <div id="idaho-congressional-medal-of-honor-recipients" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Idaho Congressional Medal of Honor Recipients</h1>
                <div className="blog-text">
                  {documentToReactComponents(
                    idahoCongressionalMedalOfHonorRecipientsSection
                    .childContentfulHistorySectionsSectionContentRichTextNode
                    .json,
                    options
                  )}
                  <IdahoCMoHRecpients />
                </div>
              </div>
              <hr />

              <div id="us-navy-ships-named-for-idaho" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>US Navy Ships Named for Idaho Cities, Counties, and Icons</h1>
                <div className="blog-text">
                  {documentToReactComponents(
                    usNavyShipsNamedForIdaho
                    .childContentfulHistorySectionsSectionContentRichTextNode
                    .json,
                    options
                  )}
                </div>
              </div>
              <hr />

              {/* <div id="native-american-warriors" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Native American Warriors</h1>
                <p className="blog-text">
                  {documentToReactComponents(
                      nativeAmericanWarriorsSection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json, options)}
                </p>
              </div>
              <hr /> */}

              <div id="submarine-documentaries-books" className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Submarine Documentaries &amp; Books</h1>
                <p className="blog-text">
                  {documentToReactComponents(
                      submarineDocumentariesSection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json, options)}
                </p>
              </div>
              <hr />

              <div id="idaho-military-history-documentaries-books" className="jumpTarget">
                <h1>Idaho Military History Documentaries &amp; Books</h1>
                <p className="blog-text">
                  {documentToReactComponents(
                      idahoMilitaryHistoryDocumentariesBooksSection
                      .childContentfulHistorySectionsSectionContentRichTextNode
                      .json, options)}
                </p>
              </div>
              <hr />
              <div id="about-the-historian" className="jumpTarget">
                <h1>About the Historian</h1>
                <h3>Gayle Alvarez</h3>
                <Img fluid={data.gayleAlvarezPhoto.childImageSharp.fluid} alt="Gayle Alvarez, Historian" style={{width: '200px'}} className="rounded float-right"/>
                <p className="blog-text">
                  <ul>
<li>Founding member of Idaho Military Historical Society (IMHS) and Museum, served on the Board of Directors for 23 years. </li>
<li>Researched and Produced Pass In Review, the IMHS’ newsletter for 17 years. </li>
<li>Founding member and Board member of Medal of Honor Historical Society of the US.  Have researched Idaho’s Medal of Honor recipients for 22 years.</li>
<li>Retired from Idaho Military Division after 34 years working in both Army and Air Force offices. </li>
<li>Served 13 years in the Idaho National Guard rising to rank of Sergeant First Class.</li>
<li>Co-author of Images of America, Farragut Naval Training Station</li>
<li>Editor and co-author of The History of the 148th Field Artillery 1940-1946, an Idaho unit that served in WWII.</li>
                  </ul>
                </p>
              </div>
            </Col>

          </Row>
        </ContentBlock>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    usNavyShipsNamedForIdaho: 
      allContentfulHistorySections(filter:{contentful_id:{eq: "QeDxVLTM7D64OWapZosR5"}}) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
    ussIdahoShips: allContentfulHistorySections(
      filter: { sectionTitle: { eq: "USS IDAHO Ships" } }
    ) {
      edges {
        node {
          childContentfulHistorySectionsSectionContentRichTextNode {
            json
          }
        }
      }
    }
    idahoNavalHistory: allContentfulHistorySections(
      filter: { sectionTitle: { eq: "Idaho Naval History" } }
    ) {
      edges {
        node {
          childContentfulHistorySectionsSectionContentRichTextNode {
            json
          }
        }
      }
    }
    idahoNationalGuard: allContentfulHistorySections(
        filter: { sectionTitle: { eq: "Idaho National Guard" } }
      ) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
      idahoArmyHistory: allContentfulHistorySections(
        filter: { sectionTitle: { eq: "Idaho Army History" } }
      ) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
      idahoAirForceHistory: allContentfulHistorySections(
        filter: { sectionTitle: { eq: "Idaho Air Force History" } }
      ) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
      idahoMarineCorpsHistory: allContentfulHistorySections(
        filter: { sectionTitle: { eq: "Idaho Marine Corps" } }
      ) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
      idahoSubmarinersOnEternalPatrol: allContentfulHistorySections(
        filter: { sectionTitle: { eq: "Idaho Submariners on Eternal Patrol" } }
      ) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
      idahoCongressionalMedalOfHonorRecipients: allContentfulHistorySections(
        filter: { sectionTitle: { eq: "Idaho Medal of Honor Recipients" } }
      ) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
      nativeAmericanWarriors: allContentfulHistorySections(
        filter: { sectionTitle: { eq: "Native American Warriors" } }
      ) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
      submarineDocumentaries: allContentfulHistorySections(
        filter: { sectionTitle: { eq: "Submarine Documentaries" } }
      ) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
      idahoMilitaryHistoryDocumentariesBooks: allContentfulHistorySections(
        filter: { sectionTitle: { eq: "Idaho Military History Documentaries & Books" } }
      ) {
        edges {
          node {
            childContentfulHistorySectionsSectionContentRichTextNode {
              json
            }
          }
        }
      }
      gayleAlvarezPhoto: file(relativePath: { eq: "GayleAlvarez.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
`

export default History
