import React from 'react'

const SmithMInfo = (
    <>
    <p>Name:  SMITH, Maynard Harrison<br />
Service:  U.S. Army Air Corps<br />
Conflict:  WWII<br />
Unit:  423d Bombardment Squadron, 306th Bombardment Group, 8th Air Force<br />
Location and Date of Act:  Brest, France, 1 May 1943<br />
Entered Service:  Caro, Michigan<br />
Date of Birth:  19 May 1911<br />
Place of Birth:  Caro, Michigan<br />
Served as:  Gunner on a B-17<br />
Current Location:  Arlington National Cemetery (Section 66, Grave 7375), Arlington, Virginia  (Died 11 May 1984)
</p>

<p>Citation:  For conspicuous gallantry and intrepidity in action above and beyond the call of duty.  The aircraft of which Sergeant Smith was a gunner was subjected to intense enemy antiaircraft fire and determined fighter airplane attacks while returning from a mission over enemy-occupied continental Europe on 1 May 1943.  The airplane was hit several times by antiaircraft fire and cannon shells of the fighter airplanes, two of the crew were seriously wounded, the aircraft’s oxygen system shot out, and several vital control cables severed when intense fires were ignited simultaneously in the radio compartment and waist sections.  The situation became so acute that three of the crew bailed out into the comparative safety of the sea.  Sergeant Smith, then on his first combat mission, elected to fight the fire by himself, administered first aid to the wounded tail gunner, manned the waist guns, and fought the intense flames alternately.  The escaping oxygen fanned the fire to such intense heat that the ammunition in the radio compartment began to explode, the radio, gun mount, and camera were melted, and the compartment completely gutted.  Sergeant Smith threw the exploding ammunition overboard, fought the fire until all the firefighting aids were exhausted, manned the workable guns until the enemy fighters were driven away, further administered first aid to his wounded comrade, and then by wrapping himself in protecting cloth, completely extinguished the fire by hand.  This soldier’s gallantry in action, undaunted bravery, and loyalty to his aircraft and fellow crewmembers, without regard for his own personal safety, is an inspiration to the U.S. Armed Forces.
</p>

<p>Notes:  In December 1942, Smith was promoted to staff sergeant in Pocatello, Idaho, where he was assigned to the 413th Bomb Squadron.  He was also the first enlisted airman to earn the nation's highest award.</p>
    </>
)

export default SmithMInfo