import React from 'react'

const FisherInfo = (
    <>
    <p>
    Name:  FISHER, Bernard Francis<br />
Service: U.S. Air Force<br />
Conflict: Vietnam<br />
Location and Date of Act:  Bien Hoa &amp; Pleiku, Vietnam, 10 March 1966<br />
Entered Service:  Kuna, Idaho<br />
Date of Birth:  11 January 1927<br />
Place of Birth:  San Bernardino, California<br />
Current Location:  Idaho State Veterans Cemetery (Section 12, Row I, Grave 142), Boise, Idaho  (Died 16 August 2014)</p>
<p>Citation:  For conspicuous gallantry and intrepidity at the risk of his life above and beyond the call of duty.  On 10 March 1966, the Special Forces camp at A Shau was under attack by 2,000 North Vietnamese Army regulars.  Hostile troops had positioned themselves between the airstrip and the camp.  Other hostile troops had surrounded the camp and were continuously raking it with automatic weapons fire from the surrounding hills.  The tops of the 1,500-foot hills were obscured by an 800-foot ceiling, limiting aircraft maneuverability and forcing pilots to operate within range of hostile gun positions, which often were able to fire down on attacking aircraft.  During the battle, Major Bernard Fisher, 1st Air Commandos, observed a fellow airman crash land on the battle-torn airstrip.  In the belief that the downed pilot was seriously injured and in imminent danger of capture, Major Fisher announced his intention to land on the airstrip to effect a rescue.  Although aware of the extreme danger and likely failure of such an attempt, he elected to continue.  Directing his own air cover, he landed his aircraft and taxied almost the full length of the runway, which was littered with battle debris and parts of an exploded aircraft.  While effecting a successful rescue of the down pilot, heavy ground fire was observed, with 19 bullets striking his aircraft.  In the face of withering ground fire, he applied power and gained enough speed to lift-off at the overrun of the airstrip.  Major Fisher’s profound concern for his fellow airman, and at the risk of his life above and beyond the call of duty are in the highest traditions of the United States Air Force and reflect great credit upon himself and the Armed Forces of his country.</p>
<p>Notes:  Fisher received the very first Air Force Medal of Honor.  Fisher lived in Kuna, Idaho for many years after his retirement from the Air Force and early in his career, served in the Idaho National Guard.  Fisher was also a WWII veteran having served one year in the Navy.  Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/2ndQtr2000a.pdf" target="_blank" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/2ndQtr2000a.pdf</a></p>
<p>As part of the Portraits of Valor book, Mr. Fisher gave an interview about his MoH action. Click here to watch it: <a href="https://www.youtube.com/watch?v=DhX17rXFVsM" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=DhX17rXFVsM</a></p>
    </>
)

export default FisherInfo