import React from 'react'

const SmithWInfo = (
    <>
    <p>Name:  SMITH, William H.<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act: Chiricahua Mountains, Arizona, 20 October 1869<br />
Entered Service:  Cincinnati, Ohio<br />
Date of Birth:  circa 1847<br />
Place of Birth:  Lapeer, Michigan<br />
Current Location:  Unknown grave in unknown cemetery, Grangeville, Idaho, ‘In Memory Of’ Cenotaph in Prairie View Cemetery (Block 11, Lot 36A, Plot 2-next to flag pole), Grangeville, Idaho</p>

<p>Citation:  Bravery in action.</p>

<p>Notes:  Smith was Lost to History until 2013 when his Final Statement became available.  It showed that he died in the field hospital at Camp Howard, Idaho Territory and was buried in Grangeville near Mt. Idaho.  There are four possible cemeteries he could be buried in but none have record of him.  His Cenotaph was placed in 2014.  Smith was in the same action as Francis Oliver and Nicholas Meaher. </p>
    </>
)

export default SmithWInfo