import React from "react"
import { Table } from "react-bootstrap"
import IdahoMedalofHonorModalButton from "./IdahoMedalofHonorModalButton"
import { StickyContainer, Sticky } from 'react-sticky';

import { BakerInfo,
        BarterInfo,
        BleakInfo,
        ConawayInfo,
        FisherInfo,
        FosterInfo,
        BoyingtonInfo,
        BrostromInfo,
        BryanInfo,
        BushInfo,
        ClarkInfo,
        FreemanInfo,
        GarlandInfo,
        GaujotInfo,
        GreenInfo,
        HammerbergInfo,
        HaysInfo,
        HeiseInfo,
        HumphreyInfo,
        JacksonAInfo,
        JacksonJInfo,
        JohnsonInfo,
        JonesInfo,
        KaneInfo,
        KephartInfo,
        LeonardInfo,
        LesterInfo,
        LittletonInfo,
        LongfellowInfo,
        MaherInfo,
        MaxwellInfo,
        McCarterInfo,
        McCarthyInfo,
        McGonagaleInfo,
        MerriamInfo,
        MeyersInfo,
        NakamuraInfo,
        NeibaurInfo,
        NorrisInfo,
        OliverInfo,
        ParnellInfo,
        PetersonInfo,
        ReasonerInfo,
        SchoonoverInfo,
        SijanInfo,
        SmithMInfo,
        SmithWInfo,
        VanNoyInfo,
        VarnumInfo,
        WilkensInfo,
        CallaghanInfo,
        GaryInfo,
        ScottInfo,
        BensonInfo,
        HillInfo,
        CorsonInfo,
        SlabinskyInfo,
        CrouseInfo,
        WillisInfo
     } from "./Recipients"

const MedalofHonorTable = (props) => (
    <>
    <StickyContainer>

<Sticky>
{({ style, isSticky }) => <div style={{ ...style, paddingTop: isSticky ? '125px' : 'none' }}>
<Table striped bordered hover responsive width="100%"
  className="tableHeader"
  style={{
    backgroundColor: 'white',
    padding: '0.5rem',
    fontSize: '12px',
    fontWeight: 'bold'
}}>

      <tr>
        <td title="Field #1" width="30%">RECIPIENT</td>
        <td title="Field #2" width="10%">SVC</td>
        <td title="Field #3" width="30%">CONFLICT</td>
        <td title="Field #4" width="6%">ACCD</td>
        <td title="Field #5" width="6%">BORN</td>
        <td title="Field #6" width="6%">LIVING/Lived</td>
        <td title="Field #7" width="6%">BURIED</td>
        <td title="Field #8" width="6%">EARNED</td>
      </tr>
  </Table>
  </div>}
  
</Sticky>
  <Table striped bordered hover responsive width="100%">
    {/* <thead>
      <tr>
        <th title="Field #1">RECIPIENT</th>
        <th title="Field #2">SVC</th>
        <th title="Field #3">CONFLICT</th>
        <th title="Field #4">ACCD</th>
        <th title="Field #5">BORN</th>
        <th title="Field #6">LIVING/Lived</th>
        <th title="Field #7">BURIED</th>
        <th title="Field #8">EARNED</th>
      </tr>
    </thead> */}
    
    <tbody>
      <tr>
        <td width="30%"><IdahoMedalofHonorModalButton
                modalContent={BarterInfo}
                recepientName="BARTER, Gurdon H."
            />
        </td>
        <td width="10%">Navy</td>
        <td width="30%">Civil War</td>
        <td width="6%"> </td>
        <td width="6%"> </td>
        <td width="6%">X</td>
        <td width="6%">X</td>
        <td width="6%"> </td>
      </tr>
      <tr>
        <td>
        <IdahoMedalofHonorModalButton
                modalContent={ConawayInfo}
                recepientName="CONAWAY, John Wesley"
            />
            </td>
        <td>Army</td>
        <td>Civil War</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={HaysInfo}
                recepientName="HAYS, John H."
            /></td>
        <td>Army</td>
        <td>Civil War</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={KephartInfo}
                recepientName="KEPHART, James"
            /></td>
        <td>Army</td>
        <td>Civil War</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={MerriamInfo}
                recepientName="MERRIAM, Henry Clay"
            /></td>
        <td>Army</td>
        <td>Civil War</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={CorsonInfo}
                recepientName="CORSON, Joseph Kirby"
            /></td>
        <td>Army</td>
        <td>Civil War</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={BryanInfo}
                recepientName="BRYAN, William Charles"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={ClarkInfo}
                recepientName="CLARK, Wilfred"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>X</td>
      </tr>
      <tr>
        <td>
        <IdahoMedalofHonorModalButton
                modalContent={GarlandInfo}
                recepientName="GARLAND, Harry"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>X</td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={GreenInfo}
                recepientName="GREEN, John"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={HeiseInfo}
                recepientName="HEISE, Richard Clamor"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={HumphreyInfo}
                recepientName="HUMPHREY, Charles Frederick"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>X</td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={JacksonJInfo}
                recepientName="JACKSON, James"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td>X</td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={JonesInfo}
                recepientName="JONES, William H."
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>X</td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={LeonardInfo}
                recepientName="LEONARD, Patrick Thomas"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={MaherInfo}
                recepientName="MAHER aka Meaher, Nicholas"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={McCarthyInfo}
                recepientName="MCCARTHY, Michael"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>X</td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={OliverInfo}
                recepientName="OLIVER, Francis"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={ParnellInfo}
                recepientName="PARNELL, William Russell"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td>X</td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={SmithWInfo}
                recepientName="SMITH, William H."
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>      </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={VarnumInfo}
                recepientName="VARNUM, Charles Albert"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={WilkensInfo}
                recepientName="WILKENS, Henry"
            /></td>
        <td>Army</td>
        <td>Indian Campaigns</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>X</td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={CrouseInfo}
                recepientName="CROUSE, William A."
            /></td>
        <td>Navy</td>
        <td>Spanish-American War – Honorable Mention</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={LongfellowInfo}
                recepientName="LONGFELLOW, Richard Moses"
            /></td>
        <td>Army</td>
        <td>Philippine Insurrection</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={FosterInfo}
                recepientName="FOSTER, Paul Frederick"
            />
            </td>
        <td>Navy</td>
        <td>Vera Cruz (Mexico)</td>
        <td>@</td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={GaujotInfo}
                recepientName="GAUJOT, Julian"
            /></td>
        <td>Army</td>
        <td>Vera Cruz (Mexico)</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={NeibaurInfo}
                recepientName="NEIBAUR, Thomas Croft"
            /></td>
        <td>Army</td>
        <td>World War I</td>
        <td>X</td>
        <td>X</td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td>
            <IdahoMedalofHonorModalButton
                modalContent={BakerInfo}
                recepientName="BAKER, Vernon Joseph"
            />
        </td>
        <td>Army</td>
        <td>World War II</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td>
        <IdahoMedalofHonorModalButton
                modalContent={BoyingtonInfo}
                recepientName="BOYINGTON, Gregory Pappy"
            /></td>
        <td>USMC</td>
        <td>World War II</td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td> <IdahoMedalofHonorModalButton
                modalContent={BrostromInfo}
                recepientName="BROSTROM, Leonard Carl"
            /></td>
        <td>Army</td>
        <td>World War II</td>
        <td>X</td>
        <td>X</td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={BushInfo}
                recepientName="BUSH, Robert Eugene"
            /></td>
        <td>Navy</td>
        <td>World War II </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>

      
      <tr>
        <td>
            <IdahoMedalofHonorModalButton
                modalContent={JacksonAInfo}
                recepientName="JACKSON, Arthur J."
            />
            </td>
        <td>USMC  </td>
        <td>World War II</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={KaneInfo}
                recepientName="KANE, John Riley"
            />
            </td>
        <td>Army Air Corps</td>
        <td>World War II </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={LesterInfo}
                recepientName="LESTER, Fredrick Faulkner"
            /></td>
        <td>Navy Reserve</td>
        <td>World War II </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={MaxwellInfo}
                recepientName="MAXWELL, Robert Dale"
            /></td>
        <td>Army</td>
        <td>World War II</td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={McCarterInfo}
                recepientName="MCCARTER, Lloyd George"
            /></td>
        <td>Army</td>
        <td>World War II</td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={NakamuraInfo}
                recepientName="NAKAMURA, William Kenzo"
            /></td>
        <td>Army</td>
        <td>World War II</td>
        <td>X</td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={PetersonInfo}
                recepientName="PETERSON, Oscar Verner"
            /></td>
        <td>Navy</td>
        <td>World War II</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>#</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={SmithMInfo}
                recepientName="SMITH, Maynard Harrison"
            /></td>
        <td>Army Air Corps</td>
        <td>World War II </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={VanNoyInfo}
                recepientName="VAN NOY, Jr., Nathan K."
            /></td>
        <td>Army</td>
        <td>World War II</td>
        <td>X</td>
        <td>X</td>
        <td> </td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td>
        <IdahoMedalofHonorModalButton
                modalContent={BleakInfo}
                recepientName="BLEAK, David Bruce"
            />
            </td>
        <td>Army </td>
        <td>Korean War</td>
        <td>X</td>
        <td>X</td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={JohnsonInfo}
                recepientName="JOHNSON, James Edmund"
            /></td>
        <td>USMC</td>
        <td>Korean War</td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td>**</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={LittletonInfo}
                recepientName="LITTLETON, Herbert A"
            /></td>
        <td>USMC</td>
        <td>Korean War</td>
        <td>X</td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={MeyersInfo}
                recepientName="MYERS, Reginald Rodney"
            /></td>
        <td>USMC</td>
        <td>Korean War</td>
        <td>X</td>
        <td>X</td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={SchoonoverInfo}
                recepientName="SCHOONOVER, Dan D."
            /></td>
        <td>Army</td>
        <td>Korean War</td>
        <td>X</td>
        <td>X</td>
        <td> </td>
        <td>+</td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={FisherInfo}
                recepientName="FISHER, Bernard Francis"
            /></td>
        <td>USAF</td>
        <td>Vietnam</td>
        <td>X</td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={FreemanInfo}
                recepientName="FREEMAN, Ed W."
            /></td>
        <td>Army</td>
        <td>Vietnam</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={McGonagaleInfo}
                recepientName="MCGONAGLE, William Loren"
            /></td>
        <td>Navy</td>
        <td>Vietnam Era </td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={NorrisInfo}
                recepientName="NORRIS, Thomas Rolland"
            /></td>
        <td>Navy</td>
        <td>Vietnam</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={ReasonerInfo}
                recepientName="REASONER, Frank Stanley"
            /></td>
        <td>USMC</td>
        <td>Vietnam</td>
        <td>X</td>
        <td> </td>
        <td>X</td>
        <td>X</td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={BensonInfo}
                recepientName="BENSON, James"
            /></td>
        <td>Navy</td>
        <td>Interim 1871-1898 - Honorable Mention</td>
        <td> </td>
        <td> </td>
        <td></td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={WillisInfo}
                recepientName="Willis, Richard"
            /></td>
        <td>Army</td>
        <td>Civil War - Honorable Mention</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={CallaghanInfo}
                recepientName="CALLAGHAN, Daniel Judson"
            /></td>
        <td>Navy</td>
        <td>World War II - Honorable Mention</td>
        <td> </td>
        <td> </td>
        <td></td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={GaryInfo}
                recepientName="GARY, Donald Arthur"
            /></td>
        <td>Navy</td>
        <td>World War II - Honorable Mention</td>
        <td> </td>
        <td> </td>
        <td></td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={HammerbergInfo}
                recepientName="HAMMERBERG, Owen Francis Patrick"
            /></td>
        <td>NAVY</td>
        <td>World War II - Honorable Mention</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={HillInfo}
                recepientName="HILL, Edwin Joseph"
            /></td>
        <td>NAVY</td>
        <td>World War II - Honorable Mention</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={ScottInfo}
                recepientName="SCOTT, Norman"
            /></td>
        <td>Navy</td>
        <td>World War II - Honorable Mention</td>
        <td> </td>
        <td> </td>
        <td></td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={SijanInfo}
                recepientName="SIJAN, Lance P."
            /></td>
        <td>USAF</td>
        <td>Vietnam - Honorable Mention</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
      <tr>
        <td><IdahoMedalofHonorModalButton
                modalContent={SlabinskyInfo}
                recepientName="SLABINSKI, Britt Kelly"
            /></td>
        <td>Navy</td>
        <td>War on Terror-Afghanistan</td>
        <td> </td>
        <td> </td>
        <td>X</td>
        <td> </td>
        <td> </td>
      </tr>
    </tbody>
  </Table>
  </StickyContainer>
  <p># Oscar Peterson was buried at sea and has an 'In Memory Of' Cenotaph in Richfield, Idaho</p>
  <p>** James E. Johnson is MIA, Missing In Action.  He has an ‘In Memory Of’ Cenotaph in Pocatello, Idaho, and Virginia’s Arlington National Cemetery</p>
  <p>@ Documents received from the Naval Academy confirm that Foster received his appointment to the Academy from Idaho Senator Dubois.  Foster remains officially accredited to Kansas as the Navy would not consider a records correction without his family’s endorsement.</p>
  <p>+ Schoonover is MIA, Missing In Action. He has an ‘In Memory Of’ Cenotaph in Morris Hill Cemetery, Boise, Idaho</p>
  <p><strong>Other noteworthy mentions:</strong></p>
<p>John Chowning Gresham, an Indian Campaigns recipient, was from March 1908 to May 1908 "commanding post" at Boise Barracks, Idaho and from May 1908 until July 1908, commanded both "regiment and post" at the Barracks.</p>

<p>Lloyd Milton Brett, an Indian Campaigns recipient, served as Post Commander at Boise Barracks, Idaho from February to September 1910. </p>

<p>Hugh Jocelyn McGrath, a Philippines Insurrection recipient, was assigned to Fort Sherman, (Coeur d’ Alene) Idaho during September 1891 and again from September 1894 to August 1895.</p>

<p>Albert Leopold Mills, a Spanish American War recipient was stationed at Ft. Coeur d’ Alene, Idaho in 1882 and again in 1884. </p> 

<p>Leon Robert Vance Jr., a WWII recipient, was stationed at Gowen Field, Boise, Idaho for roughly one month in the fall of 1943.  </p>

<p>Jonathan Mayhew Wainwright, a WWII recipient, was assigned to Boise Barracks, Idaho from February to June 1910.  Also of note, his father also spent time in Idaho both assigned to Boise Barracks and during the Nez Perce War.</p>

<p>John Moran, an Indian Campaigns recipient, may be buried in eastern Idaho.  Details are sketchy and varied but local history has him there.  He is officially classified as "Lost to History."</p>

<p>Wilmon Whilldin Blackmar, a Civil War recipient and Commander-In-Chief of the GAR, died during a visit to Boise, Idaho, on Sunday, July 16, 1905.</p> 

<p>David Charles Dolby, a Vietnam War recipient, died during a visit to Spirit Lake, Idaho on August 6, 2010. </p>

<p>Details are very limited but there is credible information that Chester H. West, a WWI recipient, lived in Idaho Falls, Idaho, for a period of time prior to enlisting in the military.</p>  

<p>Also of note, Gowen Field, Boise, Idaho, was a training center during WWII for B-17 and B-24 Bomber Crews.  A number of units transitioned through Gowen Field and four Medal of Honor Recipients are associated with those units. They are:<br />

Henry E. Erwin - 29th Bombardment Group, 52nd Bombardment Sqdn<br />
Forrest L. Vosler - 303rd Bombardment Group, 358th Bombardment Sqdn<br />
Jack W. Mathis - 303rd Bombardment Group, 359th Bombardment Sqdn<br />
Horace S. Carswell - 308th Bombardment Group, 374th Bombardment Sqdn</p>

<p>And in closing, eastern Idaho’s Bingham County is named for Henry Harrison Bingham, a Civil War recipient.  He never lived in Idaho but was a "longtime friend and political associate" of territorial Governor William M. Bunn.</p>

</>
)

export default MedalofHonorTable
