import React from 'react'

const MerriamInfo = (
    <>
    <p>Name:  MERRIAM, Henry Clay<br />
Service:  U.S. Army<br />
Conflict:  Civil War<br />
Location and Date of Act:  Fort Blakely, Alabama, 9 April 1865<br />
Entered Service:  Houlton, Maine<br />
Date of Birth:  13 November 1837<br />
Place of Birth:  Houlton, Maine <br />
Current Location:  Arlington National Cemetery (Section 1, Grave 114-B), Arlington, VA  (Died 18 November 1912)</p>

<p>Citation:  Volunteered to attack the enemy's works in advance of orders and, upon permission being given, made a most gallant assault.</p>

<p>Notes:  Merriam was transferred to Ft. Lapwai, Idaho on 9 July 1877 shortly after the outbreak of the Nez Perce War.  From there he was transferred to northern Idaho in February 1878 to design and construct Fort Sherman, later named Fort Coeur d’ Alene.  Merriam became its commander and remained there until September 1879.  In 1899, when Coeur d’ Alene miners began rioting and the Governor requested federal troops to assist in restoring order, Merriam returned to Idaho.  Although he was not physically in Idaho for the entire time, he was the temporary commander of Shoshone County from May 1899 until late May 1900.</p>
    </>
)

export default MerriamInfo