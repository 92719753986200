import React from 'react'

const MaherInfo = (
    <>
    <p>Name:  MAHER, Nicholas aka Nicholas Meaher<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Chiricahua Mountains, Arizona, 20 October 1869<br />
Entered Service:  Cincinnati, Ohio<br />
Date of Birth:  circa 1845<br />
Place of Birth:  Perry County, Ohio<br />
Current Location:  Jordan Valley Cemetery, Jordan Valley, Oregon (Section 109, Space 7)</p>

<p>Citation:  Bravery in action.
</p>

<p>Notes:  Maher, the true spelling of his name, was listed as Meaher on the Medal of Honor rolls.  He was classified as Lost to History until January 2011 when it was discovered he was residing in the Soldiers Home in Boise, Idaho at the time of his passing.  The variation in the spelling of his name caused this status.  Additionally, he lived in Silver City, Idaho in 1880 and worked there as a miner. He was in the same action as Francis Oliver and William H. Smith.</p>
    </>
)

export default MaherInfo