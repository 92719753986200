import React from 'react'

const BarterInfo = (
    <>
    <p>
    Name: BARTER, Gurdon H.<br/>
    Service:  U.S. Navy<br/>
Conflict:  Civil War<br/>
Location and Date of Act: USS Minnesota/Ft. Fisher, 15 January 1865<br/>
Entered Service:  New York, New York<br/>
Date of Birth:  1843<br/>
Place of Birth:  Williamsburg, New York<br/>
Current Location:  Barter died on 22 April 1900 and there are two cemeteries that claim to have his grave.  One is Moscow Cemetery (Lot 28, Block 1) in Moscow, Idaho, the other is Viola Cemetery (Plot 66, Grave 3), Viola, Idaho.  The Medal of Honor Headstone is at the grave in Viola’s Cemetery.  The Medal of Honor stone was to be moved to the Moscow location, his likely true grave, but that has been placed on hold.</p>
<p>Citation:  On board the U.S.S. Minnesota in action during the assault on Fort Fisher, 15 January 1865.  Landing on the beach with the assaulting party from his ship, Landsman Barter advanced to the top of the sandhill and partly through the breach in the palisades despite enemy fire which killed and wounded many officers and men.  When more than two-thirds of the men became seized with panic and retreated on the run, he remained with the party until dark, when it came safely away, bringing its wounded, its arms, and its colors.</p>

<p>Notes:  The exact date Barter came to Idaho is not known, but records confirm he was here in 1889 until his death in Viola on 22 April 1900.  Barter’s citation notes his actions on 15 January 1865 but his Medal is engraved “Dec. 1864 & Jan 1865”.  On March 25, 2006, National Medal of Honor Day, then Congressman Otter formally presented Landsman Gurdon H. Barter's actual Medal of Honor to the Idaho Military History Museum in Boise, Idaho.  It is now on exhibit there.</p>
    </>
)

export default BarterInfo