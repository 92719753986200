import React from 'react'

const VarnumInfo = (
    <>
    <p>Name:  VARNUM, Charles Albert<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  White Clay Creek, South Dakota, 30 December 1890<br />
Entered Service:  Pensacola, Florida<br />
Date of Birth:  21 June 1849<br />
Place of Birth:  Troy, New York<br />
Current Location:  San Francisco National Cemetery (Section OS, Row 3A, Site 3), San Francisco, California  (Died 26 February 1936)</p>

<p>Citation:  While executing an order to withdraw, seeing that a continuance of the movement would expose another troop of his regiment to being cut off and surrounded, he disregarded orders to retire, placed himself in front of his men, led a charge upon the advancing Indians, regained a commanding position that had just been vacated, and thus insured a safe withdrawal of both detachments without further loss.</p>

<p>Notes:  Although officially retired in 1907, Varnum requested a new assignment and was at once detailed as Instructor of the Idaho National Guard in Boise and remained there until the summer of 1909.  Varnum’s West Point obituary reported that Idaho’s Governor sought to make him a Colonel on his staff.</p>
    </>
)

export default VarnumInfo