import React from 'react'

const LesterInfo = (
    <>
    <p>Name:  LESTER, Fredrick Faulkner<br />
Service:  U.S. Navy Reserve<br />
Conflict:  WWII<br />
Unit:  Assault Rifle Platoon, 1st Battalion, 22d Marines, 6th Marine Division<br />
Location and Date of Act:  Okinawa Shima, Ryukyu Islands, 8 June 1945<br />
Entered Service:  Chicago, Illinois<br />
Date of Birth:  29 April 1926<br />
Place of Birth:  Downers Grove, Illinois<br />
Current Location:  Clarendon Hills Cemetery (Section G, Block K, Lot 28AB, Grave 9), Darien, Illinois  (Died 8 June 1945).
</p>

<p>Citation:  For conspicuous gallantry and intrepidity and the risk of his life above and beyond the call of duty while serving as a Medical Corpsman with an Assault Rifle Platoon, attached to the 1st Battalion, 22d Marines, 6th Marine Division, during action against enemy Japanese forces on Okinawa Shima in the Ryukyu Chain, 8 June 1945.  Quick to spot a wounded marine Iying in an open field beyond the front lines following the relentless assault against a strategic Japanese hill position, Lester unhesitatingly crawled toward the casualty under a concentrated barrage from hostile machineguns, rifles, and grenades.  Torn by enemy rifle bullets as he inched forward, he stoically disregarded the mounting fury of Japanese fire and his own pain to pull the wounded man toward a covered position.  Struck by enemy fire a second time before he reached cover, he exerted tremendous effort and succeeded in pulling his comrade to safety where, too seriously wounded himself to administer aid, he instructed two of his squad in proper medical treatment of the rescued marine.  Realizing that his own wounds were fatal, he staunchly refused medical attention for himself and, gathering his fast-waning strength with calm determination, coolly and expertly directed his men in the treatment of two other wounded marines, succumbing shortly thereafter.  Completely selfless in his concern for the welfare of his fighting comrades, Lester, by his indomitable spirit, outstanding valor, and competent direction of others, had saved the life of one who otherwise must have perished and had contributed to the safety of countless others.  Lester’s fortitude in the face of certain death sustains and enhanced the highest traditions of the United States Naval Service.  He gallantly gave his life for his country.</p>

<p>Notes:  Lester attended Basic Training at Farragut, Idaho, graduating in December 1943.</p>
    </>
)

export default LesterInfo