import React from 'react'

const McCarterInfo = (
    <>
    <p>Name:  MCCARTER, Lloyd George<br />
Service:  U.S. Army<br />
Conflict:  WWII<br />
Location and Date of Act:  Corregidor, Philippine Islands, 16-19 February 1945<br />
Entered Service:  Tacoma, Washington<br />
Date of Birth:  11 May 1917<br />
Place of Birth:  St. Maries, Idaho<br />
Current Location:  Woodlawn Cemetery (Block 23, Lot 7, Grave 6, Original Edition), St. Maries, Idaho  (Died 2 Feb 1956)</p>

<p>Citation:  He was a scout with the regiment which seized the fortress of Corregidor, Phillippine Islands.  Shortly after the initial parachute assault on 16 February 1945, he crossed 30 yards of open ground under intense enemy fire and at point-blank range silenced a machinegun with hand grenades.  On the afternoon of 18 February he killed six snipers.  That evening, when a large force attempted to bypass his company, he voluntarily moved to an exposed area and opened fire.  The enemy attacked his position repeatedly throughout the night and was each time repulsed.  By two o’clock in the morning, all the men about him had been wounded, but shouting encouragement to his comrades and defiance at the enemy, he continued to bear the brunt of the attack, fearlessly exposing himself to locate enemy soldiers and then pouring heavy fire on them.  He repeatedly crawled back to the American line to secure more ammunition.  When his submachine gun would no longer operate, he seized an automatic rifle and continued to inflict heavy casualties.  This weapon, in turn, became too hot to use and, discarding it, he continued with an M1 rifle.  At dawn the enemy attacked with renewed intensity.  Completely exposing himself to hostile fire, Private McCarter stood erect to locate the most dangerous enemy positions.  He was seriously wounded, but, though he had already killed more than 30 of the enemy, he refused to evacuate until he had pointed out immediate objectives for attack.  Through his sustained and outstanding heroism in the face of grave and obvious danger, Private McCarter made outstanding contributions to the success of his company and to the recapture of Corregidor.</p>

<p>Notes:  McCarter served in the Idaho National Guard prior to entering active duty.  McCarter enlisted in the 148th Field Artillery, Idaho National Guard and was a member of Headquarters Battery, 2nd Battalion, 148th Field Artillery when it was activated for WWII service and sent to Fort Lewis.  His enlistment and discharge papers state that he entered active duty in Idaho yet his Medal of Honor is accredited to Washington state.  The McCarter family history states that he went to live with his sister in Tacoma and that was where he enlisted.  He apparently listed her address as his home of record; hence his Medal is officially accredited to Washington.</p>
    </>
)

export default McCarterInfo