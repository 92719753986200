import React from 'react'

const HumphreyInfo = (
    <>
    <p>Name:  HUMPHREY, Charles Frederick<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Clearwater, Idaho, 11 July 1877<br />
Entered Service:  Buffalo, New York<br />
Date of Birth:  2 September 1844<br />
Place of Birth:  Cortland, New York  (There are two recorded birth location, one is Cortland, the other is Tioga)<br />
Current Location:  Arlington National Cemetery, (Section 4, Grave 3115), Arlington, Virginia  (Died 4 June 1926)</p>
<p>Citation:  Voluntarily and successfully conducted, in the face of a withering fire, a party which recovered possession of an abandoned howitzer and two Gatling guns lying between the lines and within a few yards of the Indians.</p>
    </>
)

export default HumphreyInfo