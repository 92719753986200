import React from 'react'

const WillisInfo = (
    <>
    <p>Name:  WILLIS, Richard <br />
Service:  U.S. Navy<br />
Conflict: Civil War<br />
Location and Date of Act: Fort Fisher, North Carolina, 24-25 December 1864 &amp; 13-15 January 1865<br />
Entered Service: New Bedford, Massachusetts (erroneously accredited to Philadelphia, Pennsylvania)<br />
Date of Birth:  His birth varies depending on the source, ranges between 1816 and 1820, often listed as 1826<br />
Place of Birth: England, possibly London<br />
Current Location: Unknown.  He died in Vallejo, California on 9 September 1896.  He is likely buried in a pauper’s grave.</p>

<p>Citation:Willis served on board the U.S.S. New Ironsides during action in several attacks on Fort Fisher, 24 and 25 December 1864; and 13, 14 and 15 January 1865.  The ship steamed in and took the lead in the ironclad division close inshore and immediately opened its starboard battery in a barrage of well-directed fire to cause several fires and explosions and dismount several guns during the first two days of fighting.  Taken under fire as she steamed into position on 13 January, the New Ironsides fought all day and took on ammunition at night, despite severe weather conditions.  When the enemy troops came out of their bombproofs to defend the fort against the storming party, the ship's battery disabled nearly every gun on the fort facing the shore before the ceasefire order was given by the flagship.</p>

<p>Notes: Willis served aboard the USS Idaho from 5 October 1867 to 8 September 1870 and again from 9 November 1870 to 31 December 1873.  While aboard the Idaho, in October 1867, he requested a replacement Medal of Honor as his original one had been stolen.  While serving his second tour on the USS Idaho, he was advanced to the rating of Yeoman.</p>
    </>
)

export default WillisInfo