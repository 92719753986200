import React from 'react'

const HillInfo = (
    <>
    <p>
    Name: HILL, Edwin Joseph<br />
    Service: U.S. Navy<br />
    Conflict: WWII<br />
    Location and Date of Act: U.S.S. Nevada, Pearl Harbor, Hawaii, 7 December 1941<br />
    Entered Service: Pennsylvania<br />
    Date of Birth: 4 October 1894 or 1895<br />
    Place of Birth: Philadelphia, Pennsylvania<br />
    Current Location: National Memorial Cemetery of the Pacific (Section A, Site 895), Honolulu, HI. (Died 7 December 1941).</p>
    <p>Citation: For distinguished conduct in the line of his profession, extraordinary courage, and disregard of his own safety during the attack on the Fleet in Pearl Harbor, by Japanese forces on 7 December 1941. During the height of the strafing and bombing, Chief Boatswain Hill led his men of the line-handling details of the U.S.S. Nevada to the quays, cast off the lines and swam back to his ship. Later, while on the forecastle, attempting to let go the anchors, he was blown overboard and killed by the explosion of several bombs.</p>
    <p>Notes: Hill served aboard the USS Idaho from May 7, 1929, to August 3, 1932.  His date of birth varies by source with compelling evidence to both dates. Hill also composed a poem called Eight Bells (And All Is Well). Exactly when he wrote it is not confirmed but after his death it was put to music by Hawaiian musicians Don George and Johnny Noble and performed by a Navy band at a USO program.</p>
    </>
)

export default HillInfo