import React from 'react'

const VanNoyInfo = (
    <>
    <p>Name:  VAN NOY, Jr., Nathan K. aka Junior Van Noy<br />
Service:  U.S. Army<br />
Conflict:  WWII<br />
Location and Date of Act:  Near Finschafen, New Guinea, 17 October 1943<br />
Entered Service:  Preston, Idaho<br />
Date of Birth:  9 August 1924<br />
Place of Birth:  Grace, Idaho<br />
Current Location:  Grace Cemetery (Plot 80, Block 2, Lot 5), Grace, Idaho (KIA 17 Oct 1943)</p>

<p>Citation:  For conspicuous gallantry and intrepidity above and beyond the call of duty in action with the enemy near Finschafen, New Guinea, on 17 October 1943.  When wounded late in September, Private Van Noy declined evacuation and continued on duty.  On 17 October 1943 he was gunner in charge of a machine-gun post only five yards from the water’s edge when the alarm was given that three enemy barges loaded with troops were approaching the beach in the early morning darkness.  One landing barge was sunk by Allied fire, but the other two beached 10 yards from Private Van Noy’s emplacement.  Despite his exposed position, he poured a withering hail of fire onto the debarking enemy troops.  His loader was wounded by a grenade and evacuated.  Private Van Noy, also grievously wounded, remained at his post, ignoring calls of nearby soldiers urging him to withdraw, and continued to fire with deadly accuracy.  He expended every round and was found, covered with wounds, dead beside his gun.  In this action Private Van Noy killed at least half of the 39 enemy taking part in the landing.  His heroic tenacity at the price of his life not only saved the lives of many of his comrades, but enabled them to annihilate the attacking detachment.
</p>

<p>Notes:  Van Noy’s name has been listed and spelled a number of different ways the most common being Junior N. Van Noy and Nathan K. Van Noy Jr.  His true name is Nathan Kilby Van Noy Jr. although he went by the name of Junior in the military.  He also signed his letters home as Junior.  History has recorded his entrance into the service location as Preston, Idaho, but according to documents obtained from the National Archives, he actually enlisted in Pocatello, Idaho on 17 February 1943.  His heroic action and death occurred eight months to the day of his enlistment.
</p>
    </>
)

export default VanNoyInfo