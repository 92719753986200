import React from 'react'

const CorsonInfo = (
    <>
    <p>
    Name: CORSON, Joseph Kirby<br />
    Service: U.S. Amry<br />
    Conflict: Civil War<br />
    Location and Date of Act: Near Bristoe Station, Virginia, 14 October 1863<br />
    Entered Service: Philadelphia, Pennsylvania<br />
    Date of Birth: 26 November 1836<br />
    Place of Birth: Plymouth Meeting, Pennsylvania <br />
    <p>Citation: With one companion returned in the face of the enemy's heavy artillery fire and removed to a place of safety a severely wounded soldier who had been left behind as the regiment fell back.</p>
    Current Location: West Laurel Hill Cemetery (Lot 98, Bryn Mawr Section, Grave 2), Bala-Cynwyd, Pennsylvania (Died 24 July 1913).</p>
    <p>Notes: Corson was stationed at Idaho’s Fort Coeur d’ Alene/Fort Sherman from January 1887 to September 1890 serving as the Post Surgeon. He was also promoted to Major while in Idaho in January 1889.</p>
    </>
)

export default CorsonInfo