import React from 'react'

const JonesInfo = (
    <>
    <p>Name:  JONES, William Henry<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Little Muddy Creek, Montana, Camas Meadows, Idaho, 7 May and 20 August 1877 respectively.<br />
Entered Service:  Louisville, Kentucky<br />
Date of Birth:  1842<br />
Place of Birth:  Davidson County, North Carolina<br />
Current Location:  Efland Methodist Church Cemetery, North Carolina  (Died 23 Dec 1911)</p>

<p>Citation:  Gallantry in the attack against hostile Sioux Indians 7 May 1877 at Muddy Creek, Mont., and in the engagement with Nez Perce Indians at Camas Meadows, Idaho, 20 August 1877, in which he sustained a painful knee wound.</p>

<p>Notes:  The Farrier Award, presented annually to the Trooper of the Year in the 2d Armored Cavalry Regiment to recognize the most outstanding trooper in the Regiment, is named in honor of Farrier Jones.  Jones’ Service and Pension File records list his age at various times during his career but are not consistent.  History recorded his year of birth as 1842; a more realistic date is 1848 or 1849.</p>
    </>
)

export default JonesInfo