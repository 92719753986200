import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons"

const TopButton = styled.div`
    display: none;
    @media(max-width: 1024px) {
    display: block;
    width: 80px;
    height: 80px;
    background-color: #3e597fd0;
    border-radius: 5px;
    position: fixed;
    right: 10px;
    z-index: 99;
    bottom: 10px;
    cursor: pointer;
    padding: 0 10px 2px 10px;
    text-align: center;
    color: white;

    &:hover {
        background-color: #3e597f;
    }
    }
    
`;

const BackToTop = (props) => {

    function scrollToMenu() {
        const scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'smooth'
        }
        // const historyMenu = document.getElementById('history-menu')
        // historyMenu.scrollIntoView({ behavior: 'smooth', alignTo });
        window.scrollTo(scrollOptions)
      }

      return (
          <div>
          <TopButton onClick={scrollToMenu}>
              <div>
              <FontAwesomeIcon 
                icon={faCaretUp} 
                style={{
                    fontSize: '44px',
                    marginTop: '-2px'
                }}
                />
                </div>
            <div style={{
                marginTop: '-7px',
                lineHeight: '18px'
            }}>
              {props.scrollWhere}
              </div>
          </TopButton>
        </div>
      )
}

export default BackToTop