import React from 'react'
import { Link } from 'gatsby'

const IdahoMarineCorpsHistoryToC = () => (
    <>
    <p><strong><Link to="/history#company-c">Company C, 4th Tank Battalion, USMC Reserve, Gowen Field, Idaho</Link></strong></p>
    <p><strong><Link to="/history#usmc-idaho-platoons">USMC Idaho Platoons</Link></strong><br />The USMC had three Idaho platoons. These men were sworn in together, left for basic training together and were in the same platoon throughout their basic training.</p>
    </>
)

export default IdahoMarineCorpsHistoryToC