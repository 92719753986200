import React from 'react'

const BensonInfo = (
    <>
    <p>
    Name: BENSON, James<br />
    Service: U.S. Navy<br />
    Conflict: Interim 1871-1898<br />
    Location and Date of Act: U.S.S. Ossipee, At Sea, 20 June 1872<br />
    Entered Service: New York (erroneously accredited to Japan)<br />
    Date of Birth: 1842<br />
    Place of Birth: Denmark<br />
    Current Location: Woodlawn Cemetery (Astral Place, Grave 28), Everett, Massachusetts (Died 4 August 1890).</p>
    <p>Citation: On board the U.S.S. Ossipee, 20 June 1872.  Risking his life, Benson leaped into the sea while the ship was going at a speed of 4 knots and endeavored to save John K. Smith, landsman, of the same vessel, from drowning.</p>
    <p>Notes: Benson served aboard the USS Idaho, reporting aboard on 4 May 1870 in Yokohama, Japan. He had reenlisted aboard the USS Delaware on 1 April 1870 which is believed to have been docked in Yokahama, Japan. He soon transferred to the USS Idaho which was docked in Yokohama, Japan. As such, he was erroneously accredited to Japan. His first enlistment was in New York. He transferred to Mare Island Navy yard on 22 June 1870.</p>
    </>
)

export default BensonInfo