import React from 'react'

const OliverInfo = (
    <>
    <p>Name:  OLIVER, Francis<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Chiricahua Mountains, Arizona, 20 October 1869<br />
Entered Service:  Ft. Filmore, New Mexico<br />
Date of Birth:  1832<br />
Place of Birth:  Baltimore, Maryland<br />
Current Location:  Normal Hill Cemetery (Div A, Row 19, Lot 19, Grave 1), Lewiston, Idaho  (Died 28 July 1880)</p>

<p>Citation:  Bravery in action.</p>

<p>Notes:  Oliver served in the U.S. Dragoons, the predecessor to the U.S. Cavalry, enlisting in 1855.  He was also a Civil War veteran.  After his discharge at Fort Lapwai, Idaho Territory in 1873, Oliver settled in Mt. Idaho and became a blacksmith.  He was in the same MoH action as William H. Smith and Nicholas Meaher.</p>
    </>
)

export default OliverInfo