import React from 'react'

const BryanInfo = (
    <>
    <p>
    Name:  BRYAN, William Charles<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Powder River, Wyoming, 17 March 1876<br />
Entered Service:  St. Louis, Missouri<br />
Date of Birth:  9 September 1852<br />
Place of Birth:  Zanesville, Ohio<br />
Current Location:  Fairmont Cemetery (Block 61, Lot 96, Section N. Half), Denver, Colorado  (Died 27 March 1933).</p>
<p>Citation:  Accompanied a detachment of cavalry in a charge on a village of hostile Indians and fought through the engagements, having his horse killed under him.  He continued to fight on foot, and under severe fire and without assistance conveyed two wounded comrades to places of safety, saving them from capture.</p>
<p>Notes:  Bryan came to Idaho in 1899 as part of the "Northern Idaho Exploring Expedition" and later, from 1927-1928, he served as an Assistant in Physical Education at the University of Idaho in Moscow, Idaho.</p>
    </>
)

export default BryanInfo