import React from 'react'

const MeyersInfo = (
    <>
    <p>Name:  MYERS, Reginald Rodney<br />
Service:  U.S. Marine Corps<br />
Conflict:  Korean War<br />
Location and Date of Act:  Near Hagaru-ri Korea, 29 November 1950<br />
Entered Service:  Boise, Idaho<br />
Date of Birth:  26 November 1919 <br />
Place of Birth:  Boise, Idaho<br />
Current Location:  Arlington National Cemetery, (Section 60, Grave 884), Arlington, Virginia  (Died 23 October 2005)</p>

<p>Citation:  For conspicuous gallantry and intrepidity at the risk of his life above and beyond the call of duty as executive officer of the 3d Battalion, 1st Marines, 1st Marine Division (Reinforced), in action against enemy aggressor forces.  Assuming command of a composite unit of Army and Marine service and headquarters elements totaling approximately 250 men, during a critical stage in the vital defense of the strategically important military base at Hagaru-ri, Korea, on 29 November 1950, Major Reginald Myers immediately initiated a determined and aggressive counterattack against a well-entrenched and cleverly concealed enemy force numbering an estimated 4,000.  Severely handicapped by a lack of trained personnel and experienced leaders in his valiant efforts to regain maximum ground prior to daylight, he persisted in constantly exposing himself to intense, accurate, and sustained hostile fire in order to direct and supervise the employment of his men and to encourage and spur them on in pressing the attack.  Inexorably moving forward up the steep, snow-covered slope with his depleted group in the face of apparently insurmountable odds, he concurrently directed artillery and mortar fire with superb skill and although losing 170 of his men during fourteen hours of raging combat in subzero temperatures, continued to reorganize his unit and spearhead the attack which resulted in 600 enemy killed and 500 wounded.  By his exceptional and valorous leadership throughout, Major Myers contributed directly to the success of his unit in restoring the perimeter.  His resolute spirit of self-sacrifice and unfaltering devotion to duty enhance and sustain the highest traditions of the United States Naval Service.</p>

<p>Notes:  Myers was also a WWII Veteran serving in both the Army Air Corps and the USMC.  Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/1stQtr2001.pdf" target="_blank" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/1stQtr2001.pdf</a></p>
<p>
As part of the Portraits of Valor book, Mr. Myers gave an interview about his MoH action.  Click here to watch it:  <a href="https://www.youtube.com/watch?v=4EdDKSkMUbc" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=4EdDKSkMUbc</a></p>
    </>
)

export default MeyersInfo