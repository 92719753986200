import React from 'react'
import { Link } from 'gatsby'

const IdahoAirForceHistoryToC = () => (
    <>
    <p>The Air Force did not become a separate service until 1947. Until then, the Air Bases or Air Fields as they were known, were under the jurisdiction of the Army. Idaho had three Army Air Bases during WWII. They were:</p>
    <p><strong><Link to="/history#gowen-field">Gowen Field (1941-1945; 1946-current)</Link></strong></p>
    <p><strong><Link to="/history#pocatello-army-air-base">Pocatello Army Air Base/Air Field (1942-1945; 1954-1965)</Link></strong></p>
    <p><strong><Link to="/history#mountain-home">Mountain Home Army Air Base, now Mountain Home Air Force Base (1942-1945; 1948-current)</Link></strong></p>
    <p><strong><Link to="/history#wilder-radar">Wilder Radar Bomb Scoring Site (1964-1993)</Link></strong><br />From 1964 until 1993, the Air Force operated a radar bomb scoring detachment at Wilder, Idaho 
to test the effectiveness of combat ready crews of the Strategic Air Command (SAC) bombers with simulated bomb drops.
</p>
    </>
)

export default IdahoAirForceHistoryToC