import React from 'react'

const ParnellInfo = (
    <>
    <p>Name:  PARNELL, William Russell<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  White Bird Canyon, Idaho, 17 June 1877<br />
Entered Service:  Brooklyn, New York<br />
Date of Birth:  13 August 1836<br />
Place of Birth:  Dublin Ireland<br />
Current Location:  San Francisco National Cemetery, San Francisco, California, (OS-68, Site 8)  (Died 20 August 1910)</p>

<p>Citation:  With a few men, in the face of a heavy fire from pursuing Indians and in imminent peril, returned and rescued a soldier whose horse had been killed and who had been left behind in the retreat.</p>

<p>Notes:  Parnell was also stationed at Boise Barracks, Idaho from May 1880 until August 1882.  For a portion of his time there, he was the commander of Troop F of the 1st Cavalry.  When he departed for Walla Walla, Washington, the Idaho Tri-Weekly Statesman reported that “many citizens will regret the departure of Col. Parnell…”
Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/4thQtr2001.pdf" target="_blank" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/4thQtr2001.pdf</a></p>
    </>
)

export default ParnellInfo