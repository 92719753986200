import React from 'react'

const CrouseInfo = (
    <>
    <p>Name: CROUSE, William A. <br />
Service:  U.S. Navy<br />
Conflict: Spanish-American War<br />
Location and Date of Act:  off Cavite, Manila Bay, Philippine Islands, 21 May 1898<br />
Entered Service: Mare Island, California (erroneously accredited to Pennsylvania)<br />
Date of Birth: 22 October 1866<br />
Place of Birth: Fannettsburg, Pennsylvania (erroneously recorded as Tannettsburg, PA)<br />
Current Location: Arlington Cemetery (Sunnyside Section, Lot P-15, Grave 7), Drexel Hill, Pennsylvania  (Died 27 June 1941).</p>

<p>Citation: On board the U.S.S. Concord off Cavite, Manila Bay, P.I., 21 May 1898.  Following the blowing out of a lower manhole plate joint on boiler B of that vessel, Crouse hauled the fires in the hot, vapor-filled atmosphere, which necessitated the playing of water into the fireroom from a hose.</p>

<p>Notes: Crouse served aboard the USS Idaho from November 1908 to 31 October 1910 as Chief Commissary Steward.  While aboard the Idaho, in February 1909, he received Spanish Campaign Badge #1880 for his service during the Spanish American War, Philippine Campaign Badge #1418 for his service during the Philippine Insurrection and his 4th Good Conduct Bar.  USS Idaho Muster Rolls are not available for exact date he reported aboard the Idaho.</p>
    </>
)

export default CrouseInfo