import React from 'react'

const FosterInfo = (
    <>
    <p>
    Name:  FOSTER, Paul Frederick<br />
Service:  U.S. Navy<br />
Conflict:  Vera Cruz, Mexico<br />
Location and Date of Act:  Vera Cruz, Mexico, 21 and 22 April 1914<br />
Entered Service:  Weiser, Idaho<br />
Date of Birth:  25 March 1889<br />
Place of Birth:  Wichita, Sedgwick County, Kansas<br />
Current Location:  Arlington National Cemetery (Section 5, Grave 106)  (Died 30 January 1972)</p>
<p>Citation:  For distinguished conduct in battle, engagements of Vera Cruz, 21 and 22 April 1914.  In both days’ fighting at the head of his company, Ensign Foster was eminent and conspicuous in his conduct, leading his men with skill and courage.</p>
<p>Notes:  Prior to entering the U.S. Naval Academy, Foster attended the University of Idaho and lived in Weiser, Idaho.  Documents received from the Naval Academy confirm that he received his appointment to the Academy from Idaho Senator Dubois.  Foster remains officially accredited to Kansas as the Navy would not consider a records correction without his family’s endorsement. </p>
    </>
)

export default FosterInfo