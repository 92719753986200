import React from 'react'

const MaxwellInfo = (
    <>
    <p>Name:  MAXWELL, Robert Dale<br />
Service:  U.S. Army<br />
Conflict:  WWII<br />
Location and Date of Act:  Near Besancon France, 7 September 1944<br />
Entered Service:  Creswell, Colorado<br />
Date of Birth:  26 October 1920<br />
Place of Birth:  Boise, Idaho<br />
Current Location:  Terrebonne Pioneer Cemetery (Block 1, Lot 85, Plot E), Terrebonne, Oregon  (Died 11 May 2019)</p>

<p>Citation:  For conspicuous gallantry and intrepidity at the risk of life above and beyond the call of duty on 7 September 1944, near Besancon, France.  Technician 5th Grade Maxwell and three other soldiers, armed only with .45-caliber automatic pistols, defended the battalion observation post against an overwhelming onslaught by enemy infantrymen in approximately platoon strength, supported by 20-mm flak and machine-gun fire, who had infiltrated through the battalion’s forward companies and were attacking the observation post with machine-gun, machine pistol, and grenade fire at ranges as close as 10 yards.  Despite a hail of fire from automatic weapons and grenade launchers, Technician 5th Grade Maxwell aggressively fought off advancing enemy elements and, by his calmness, tenacity, and fortitude, inspired his fellows to continue the unequal struggle.  When an enemy hand grenade was thrown in the midst of his squad, Technician 5th Grade Maxwell unhesitatingly hurled himself squarely upon it, using his blanket and his unprotected body to absorb the full force of the explosion.  This act of instantaneous heroism permanently maimed Technician 5th Grade Maxwell, but saved the lives of his comrades in arms and facilitated maintenance of vital military communications during the temporary withdrawal of the battalion’s forward headquarters.</p>

<p>Notes:  Maxwell earned a Silver Star on 31 January 1944 and on 7 September 1944, roughly one hour before he earned his Medal of Honor, he earned a second Silver Star.  Maxwell’s citation was one of the last to be signed by President Franklin D. Roosevelt.  Roosevelt died on 12 April 1945 with Maxwell’s signed citation on his desk.
Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/2ndQtr2003.pdf" target="_blank" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/2ndQtr2003.pdf</a></p>
<p>
As part of the Portraits of Valor book, Mr. Maxwell gave an interview about his MoH action. Click here to watch it: <a href="https://www.youtube.com/watch?v=q3TVFgR_bpM" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=q3TVFgR_bpM</a></p>
    </>
)

export default MaxwellInfo