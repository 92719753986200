import React from 'react'

const HaysInfo = (
    <>
    <p>Name:  HAYS, John Henry<br />
Service:  U.S. Army<br />
Conflict:  Civil War<br />
Location and Date of Act:  Columbus Georgia, 16 April 1865<br />
Entered Service:  Oskaloosa Iowa<br />
Date of Birth:  4 August 1844<br />
Place of Birth:  Jefferson County, Ohio<br />
Current Location:  Moscow Cemetery (Block 7, Lot 25, Grave 4), Moscow, Idaho  (Died 27 Jan 1904)</p>
<p>Citation:  Capture of flag and bearer, 2nd National Banner of the Confederate States of America.</p>
<p>Notes:  Hays’ headstone has two dates but his actual date of death was 1904.  He was the Marshal for the town of Troy, Idaho and was killed in the line of duty.</p>
    </>
)

export default HaysInfo