import React from 'react'

const GarlandInfo = (
    <>
    <p>
    Name:  GARLAND, Harry<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Little Muddy Creek, Montana, Camas Meadows, Idaho, 7 May and 20 August 1877 respectively.<br />
Entered Service:  Indianapolis, Indiana<br />
Date of Birth:  1844<br />
Place of Birth:  Boston, Massachusetts<br />
Current Location:  Ft. Missoula, Montana, (North Section, Row I, Grave 3)  (Died 21 May 1883)</p>
<p>Citation:  Gallantry in action with hostile Sioux at Little Muddy Creek, Mont.; having been wounded in the hip so as to be unable to stand, at Camas Meadows, Idaho, he still continued to direct the men under his charge until the enemy withdrew.</p>
<p>Notes:  History incorrectly recorded Garland’s date of action and his burial location.  The battle at Camas Meadows was 20 August and the letter recommending him for the Medal clearly states this.  The Army issued a Records correction for his Camas Meadows action in 2012.  
His burial location was incorrectly recorded as Ft. Ellis, Wyoming.  The graves at Ft. Ellis, which is actually located in Montana, were moved to Ft. Missoula, Montana, when Ft. Ellis closed around 1886.  Most sources list his death as 7 May 1883 but the Burial Register from Ft. Ellis, Montana clearly shows that he died on 21 May 1883.</p>
    </>
)

export default GarlandInfo