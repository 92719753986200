import React, {useRef, useState} from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import ShowMoreText from 'react-show-more-text';

import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import IdahoNavalHistoryToC from "../IdahoNavalHistoryToC"

const IdahoNavyHistory = () => {

  const navyRef = useRef()

  function executeOnClick(expanded) {
      console.log('expanded? ' + expanded)
      !expanded && navyRef.current.scrollIntoView({ behavior: 'smooth'});
    }

const data = useStaticQuery(graphql`
{
    intro: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "5FY7QUC2m6X3u1PixuC3R2"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
ardBayview: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "6AY2AthVbGJTcrTi5eRYKg"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
farragut: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "IszLtWplXQA4eA5PqAbEA"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
navalProvingGround: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "JhfgOnuT5Mi2QQ1lxsuaN"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
navalPrototypeTrainingUnit: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "4kgG2snFE5FJGJdUfsTpsj"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
navalOperationalSupportCenter: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "2qQnQnJp6W5jaupNvZey8m"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
navalRotc: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "J4nmqeMO4bNlX3IUFKMWk"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
navyRadioSchool: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "54FQ25JaNI1DCDQBCs2cIz"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
convalescentHospital: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "NC0saPic9PwTdH2FiRHYU"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
pocatelloOrdnancePlant: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "5Gji1IilD3h90Tz5fw5jlm"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
navyReserveFacility: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "6TM53OkmMsebOguX1cy49C"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
caaTraining: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "E1mlTZxdLcb3e9SJnQ3cd"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
navyV12School: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "711ADCv4VZVYKB54T7bYhB"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
allIdahoNavyCompany: allContentfulIdahoNavyHistory(filter:{contentful_id:{eq: "4aYmHz43wTCwnosTEp2gky"}}) {
       edges {
           node {
               childContentfulIdahoNavyHistorySectionContentRichTextNode {
                   json
         }
       }
     }
   }
}
`
);
  //const page = data.historySections.edges[0].node
  const intro = data.intro.edges[0].node;
  const ardBayview = data.ardBayview.edges[0].node;
  const farragut = data.farragut.edges[0].node;
  const navalProvingGround = data.navalProvingGround.edges[0].node;
  const navalPrototypeTrainingUnit = data.navalPrototypeTrainingUnit.edges[0].node;
  const navalOperationalSupportCenter = data.navalOperationalSupportCenter.edges[0].node;
  const navalRotc = data.navalRotc.edges[0].node;
  const navyRadioSchool = data.navyRadioSchool.edges[0].node;
  const convalescentHospital = data.convalescentHospital.edges[0].node;
  const pocatelloOrdnancePlant = data.pocatelloOrdnancePlant.edges[0].node;
  const navyReserveFacility = data.navyReserveFacility.edges[0].node;
  const caaTraining = data.caaTraining.edges[0].node;
  const navyV12School = data.navyV12School.edges[0].node;
  const allIdahoNavyCompany = data.allIdahoNavyCompany.edges[0].node;

  

console.log(data)

const columnsOptions = {
  renderNode: {
              
              [INLINES.EMBEDDED_ENTRY]: node => {
                const embed  = node.data.target.fields.leftColumnLongText
                console.log("Embed" + embed['en-US'])

                return (
                  <>
                      {/* {embed.leftColumnLongText["en-US"]} */}
                      
                        dangerouslySetInnerHTML={{
                          __html: `${embed['en-US']}`,
                        }}
                  </>
                )
            }
  }
}

const options = {
  renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({data: { target: { fields }}}) => 
          <div
          dangerouslySetInnerHTML={{
            __html: `<div style="width: auto; float: right; clear: both; margin-left: 20px; ">
            <img src="${fields.file["en-US"].url}" alt="${fields.title["en-US"]}" class="img-fluid" style="margin: 0px" />
            <p style="text-align: center; max-width: 320px;">
              <em>${fields.title["en-US"]}</em>
            </p>
            </div>`,
          }}
        />,

          [BLOCKS.EMBEDDED_ENTRY]: (node) => {
              const fields = node.data.target.fields.imageLink;
              const imgLnk = node.data.target.fields.url;
              
                  return (
                  <div dangerouslySetInnerHTML={{__html: `<a href="${imgLnk['en-US']}"><img class="img-fluid" src="${fields['en-US'].fields.file['en-US'].url}" alt="${fields.title}" style="width: 100%" /></a>` }} />
                  )
              },

              [INLINES.EMBEDDED_ENTRY]: (node) => {
                const embed = node.data.target.fields.markdownContent;
                //console.log('embed data: ' + embed['en-US'])
                return (
                    <div dangerouslySetInnerHTML={{__html: `${embed['en-US']}`}} />
                )
            },
            [INLINES.HYPERLINK]: (node) => {
              return <a href={node.data.uri} target={`${node.data.uri.startsWith('https://uss-idaho.inlightmedia.io/') ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith('https://uss-idaho.inlightmedia.io/') ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
            }

  }
}

  return (
              <div id="idaho-naval-history" ref={navyRef} className="jumpTarget" style={{marginBottom: '132px'}}>
                <h1>Idaho Naval History Brief Summaries</h1>
                <h3>Idaho has a rich Navy military history. Click the link to go directly to the article.</h3>
                <IdahoNavalHistoryToC />
                <div>
                  {documentToReactComponents(
                    intro
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}
                  </div>
                  <div id="navy-acoustic-research-detachment" class="jumpTarget">
                      <h5>U.S. Navy Acoustic Research Detachment (1949-current) Bayview, Idaho</h5>
                      <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                  {documentToReactComponents(
                    ardBayview
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}
                  </ShowMoreText>
                  </div>
                  <hr />
                  <div id="farragut-naval-training-station" class="jumpTarget">
                  <h5>Farragut Naval Training Station and Hospital (1942-1946) Athol, Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                  {documentToReactComponents(
                    farragut
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}
                  </ShowMoreText>
                  </div>
                  <hr />
                  <div id="navy-proving-ground" class="jumpTarget">
                  <h5>U.S. Naval Proving Ground (1942- 1949) currently known as INL, Arco, Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                  {documentToReactComponents(
                    navalProvingGround
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}
                  </ShowMoreText>
                  </div>
                  <hr />
                  <div id="naval-prototype-training-unit" class="jumpTarget">
                  <h5>Naval Prototype Training Unit – (1949 – 1995) Idaho Falls, Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                  {documentToReactComponents(
                    navalPrototypeTrainingUnit
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}</ShowMoreText>
                  </div>
                  <hr />
                  <div id="naval-operational-support-center" class="jumpTarget">
                  <h5>Naval Operational Support Center ( - current) Gowen Field, Boise, Idaho</h5>
                  {documentToReactComponents(
                    navalOperationalSupportCenter
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}
                  </div>
                  <hr />
                  <div>
                  <h5 id="naval-rotc-unit" class="jumpTarget">Naval ROTC Unit University of Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                  {documentToReactComponents(
                    navalRotc
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}</ShowMoreText>
                  </div>
                  <hr />
                  <div>
                  <h5 id="navy-radio-school" class="jumpTarget">U.S. Navy Radio School (1942-1945) University of Idaho, Moscow, Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                  {documentToReactComponents(
                    navyRadioSchool
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )} </ShowMoreText>
                  </div>
                  <hr />
                  <div id="navy-convalescent-hospital" class="jumpTarget">
                  <h5>U.S. Navy Convalescent Hospital (1943-1946) Sun Valley, Ketchum, Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                  {documentToReactComponents(
                    convalescentHospital
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}</ShowMoreText>
                  </div>
                  <hr />
                  <div id="pocatallo-naval-ordnance-plant" class="jumpTarget">
                  <h5>Pocatello Naval Ordnance Plant (1942-1961) Pocatello, Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                        {documentToReactComponents(
                    pocatelloOrdnancePlant
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}</ShowMoreText>
                  </div>
                  <hr />
                  <div id="pocatello-navy-reserve-facility" class="jumpTarget">
                  <h5>Navy Reserve Facility Pocatello (1959-2005) Pocatello Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                        {documentToReactComponents(
                    navyReserveFacility
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}</ShowMoreText>
                  </div>
                  <hr />
                  <div id="air-cadet-training-school" class="jumpTarget">
                  <h5>CAA (now called FAA) Air Cadet Training School (1943-1944) Lewiston, Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                  {documentToReactComponents(
                    caaTraining
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}</ShowMoreText>
                  </div>
                  <hr />
                  <div id="navy-v-12-school" class="jumpTarget">
                  <h5>U.S. Navy V-12 School (1943-1945) Pocatello, Idaho</h5>
                  <ShowMoreText
                        lines={10}
                        more={<div><strong>Read more &rsaquo;</strong></div>}
                        less={<div><strong>&lsaquo; Back to Idaho Naval History Table of Contents</strong></div>}
                        onClick={executeOnClick}
                        expanded={false}
                      >
                  {documentToReactComponents(
                    navyV12School
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}</ShowMoreText>
                  </div>
                  <hr />
                  <div id="all-idaho-navy-company" class="jumpTarget">
                  <h5>1955 All-Idaho Navy Company</h5>
                  {documentToReactComponents(
                    allIdahoNavyCompany
                      .childContentfulIdahoNavyHistorySectionContentRichTextNode
                      .json,
                    options
                  )}
                  </div>


              </div>

  )
}


export default IdahoNavyHistory
