import React from 'react'
import { Link } from 'gatsby'

const IdahoNavalHistoryToC = () => (
    <>
    <p><strong><Link to="/history#navy-acoustic-research-detachment">U.S. Navy Acoustic Research Detachment (1949-current) Bayview, Idaho</Link></strong><br/>
    Once touted as "the U.S. Submarine force's most important body of water" by Admiral Edmund Giambastiani, former US Submarine Forces Commander. 
    </p>
    <p><strong><Link to="/history#farragut-naval-training-station">Farragut Naval Training Station and Hospital (1942-1946) Athol, Idaho</Link></strong><br />The largest city in Idaho in 1942 and the second largest of seven navy bootcamps in WWII</p>
    <p><strong><Link to="/history#navy-proving-ground">U.S. Naval Proving Ground (1942- 1949) currently known as INL, Arco, Idaho</Link></strong><br />What eventually became the Idaho National Lab, this was originally a Navy artillery range and the home of the first Nuclear Prototype Submarine reactor and the home of Naval Prototype Training Unit where 40,000 sailors trained on nuclear power plant operations from 1949 to 1995</p>
    <p><strong><Link to="/history#naval-prototype-training-unit">Naval Prototype Training Unit – (1949 – 1995) Idaho Falls, Idaho</Link></strong><br />Submarine nuclear power capabilities began right here in Idaho at what was then the Navy Arco Proving ground and what is now the Idaho National Laboratory.  All of the nuclear personnel from the first nuclear submarine, USS Natilus trained in Idaho.</p>
    <p><strong><Link to="/history#naval-operational-support-center">Naval Operational Support Center ( - current) Gowen Field, Boise, Idaho</Link></strong><br />Originally located along the Boise River in what is now the Boise Discovery Center, NOSC Boise began in 1963 and moved to its current location on Gowen Field in 1988.</p>
    <p><strong><Link to="/history#naval-rotc-unit">Naval ROTC Unit University of Idaho</Link></strong><br />
    Shortly before WWII ended, the Navy established an ROTC unit at the University of Idaho.</p>
    <p><strong><Link to="/history#navy-radio-school">U.S. Navy Radio School (1942-1945) University of Idaho, Moscow, Idaho</Link></strong><br />The Navy training station at Farragut wasn’t the only place in Idaho that taught radiomen their skills. The University of Idaho also had a Navy radioman school.</p>
    <p><strong><Link to="/history#navy-convalescent-hospital">U.S. Navy Convalescent Hospital (1943-1946) Sun Valley, Ketchum, Idaho</Link></strong><br />Today’s Sun Valley Resort was called the Challenger Inn in 1943.  Owned by Union Pacific, it served as a Navy Convalescent Hospital during WWII.</p>
    <p><strong><Link to="/history#pocatallo-naval-ordnance-plant">Pocatello Naval Ordnance Plant (1942-1961) Pocatello, Idaho</Link></strong><br />When the Navy ships fighting in WWII needed their battleship guns repaired, many of those guns came to Idaho for and then were tested at the Navy artilery range or Arco Proving Ground.</p>
    <p><strong><Link to="/history#pocatello-navy-reserve-facility">Navy Reserve Facility Pocatello (1959-2005) Pocatello Idaho</Link></strong><br />Along with Boise, the Navy also had a reserve facililty in Pocatello that was known for some of the highest numbers in the nation for Direct Commissioned Officers.</p>
    <p><strong><Link to="/history#air-cadet-training-school">CAA (now called FAA) Air Cadet Training School (1943-1944) Lewiston, Idaho</Link></strong></p>
    <p><strong><Link to="/history#navy-v-12-school">U.S. Navy V-12 School (1943-1945) Pocatello, Idaho</Link></strong><br />The V-12 Program provided young men who grew up with little hope of ever going to college a chance to do just that.  When the V-12 Program ended in 1946, it had produced more than 60,000 Navy and Marine Corps officers.  One of those schools was in Pocatello, Idaho.</p>
    <p><strong><Link to="/history#all-idaho-navy-company">1955 All-Idaho Navy Company</Link></strong><br />In January 1955, eighty Idaho men became part of an all-Idaho navy unit. The unit received a special plaque from the Training Command "for exceptional and competitive performance".</p>    
    </>
)

export default IdahoNavalHistoryToC