import React from 'react'

const PetersonInfo = (
    <>
    <p>Name:  PETERSON, Oscar Verner<br />
Service:  U.S. Navy<br />
Conflict:  WWII<br />
Location and Date of Act:  7 May 1942, Coral Sea<br />
Entered Service:  Wisconsin/Missouri  (See Notes)  <br />
Date of Birth:  27 August 1899<br />
Place of Birth:  Prentise, Wisconsin<br />
Current Location:  Buried at Sea.  Peterson's widow moved to Richfield, Idaho shortly after his untimely death.  An 'In Memory Of' Cenotaph to Peterson was dedicated and placed next to his wife's grave in the Richfield cemetery (Block 9w Lot 3), Richfield, Idaho on 3 April 2010. </p>

<p>Citation:  For extraordinary courage and conspicuous heroism above and beyond the call of duty while in charge of a repair party during an attack on the U.S.S. Neosho by enemy Japanese aerial forces on 7 May 1942.  Lacking assistance because of injuries to the other members of his repair party and severely wounded himself, Peterson, with no concern for his own life, closed the bulkhead stop valves and in so doing received additional burns which resulted in his death.  His spirit of self-sacrifice and loyalty, characteristic of a fine seaman, was in keeping with the highest traditions of the United States Naval Service.  He gallantly gave his life in the service of his country.</p>

<p>Notes:  Most sources list Peterson as entering the service in Wisconsin.  He actually enlisted in Kansas City, Missouri.  Many sources also list his date of death as 7 May but this is not the case either.  He died six days after his heroic action and rescue on board the USS Henley on 13 May 1942.  Peterson’s Medal of Honor was not formally presented to his widow, it was mailed to her in Idaho.  When Peterson’s Cenotaph was dedicated, RADM James A. Symonds formally presented the Medal of Honor to Peterson’s surviving son.  The U.S. Navy’s Farragut Naval Training Station located in northern Idaho named its Service School training camp Camp Peterson in his honor.</p>
    </>
)

export default PetersonInfo