import React from 'react'

const McGonagaleInfo = (
    <>
    <p>Name:  MCGONAGLE, William Loren<br />
Service:  U.S. Navy<br />
Conflict:  Vietnam Era<br />
Location and Date of Act:  international waters near the Sinai Peninsula, 8-9 June 1967<br />
Entered Service:  Thermal, California<br />
Date of Birth:  19 November 1925<br />
Place of Birth:  Wichita, Kansas<br />
Current Location:  Arlington National Cemetery (Section 34, Grave 208), Arlington, Virginia  (Died 3 March 1999)</p>

<p>Citation:  For conspicuous gallantry and intrepidity at the risk of his life above and beyond the call of duty as Commanding Officer, U.S.S. Liberty (AGTR-5) in the Eastern Mediterranean on 8-9 June 1967.  Sailing in international waters, the Liberty was attacked without warning by jet fighter aircraft and motor torpedo boats which inflicted many casualties among the crew and caused extreme damage to the ship.  Although severely wounded during the first air attack, Captain (then Commander) McGonagle remained at his battle station on the badly damaged bridge and, with full knowledge of the seriousness of his wounds, subordinated his own welfare to the safety and survival of his command.  Steadfastly refusing any treatment which would take him away from his post, he calmly continued to exercise firm command of his ship.  Despite continuous exposure to fire, he maneuvered his ship, directed its defense, supervised the control of flooding and fire, and saw to the care of the casualties.  Captain McGonagle's extraordinary valor under these conditions inspired the surviving members of the Liberty 's crew, many of them seriously wounded, to heroic efforts to overcome the battle damage and keep the ship afloat.  Subsequent to the attack, although in great pain and weak from the loss of blood, Captain McGonagle remained at his battle station and continued to command his ship for more than 17 hours.  It was only after rendezvous with a United States destroyer that he relinquished personal control of the Liberty and permitted himself to be removed from the bridge.  Even then, he refused much needed medical attention until convinced that the seriously wounded among his crew had been treated.  Captain McGonagle's superb professionalism, courageous fighting spirit, and valiant leadership saved his ship and many lives.  His actions sustain and enhance the finest traditions of the United States Naval Service.</p>

<p>Notes:  McGonagle was Commanding Officer and Professor of Naval Science, NROTC Unit, at the University of Idaho from January 1959 to June 1961 and received a Bachelor of Science in Education from the University while teaching there.  McGonagle gave an interview about his MoH action.  Click here to watch it:  <a href="https://www.bing.com/videos/search?q=william+loren+mcgonagle+on+youtube&docid=608034388355845917&mid=4F91D12297E7F81E3B704F91D12297E7F81E3B70&view=detail&FORM=VIRE" target="_blank" rel="noopener noreferrer">https://www.bing.com/videos/search?q=william+loren+mcgonagle+on+youtube&docid=608034388355845917&mid=4F91D12297E7F81E3B704F91D12297E7F81E3B70&view=detail&FORM=VIRE</a>

</p>
    </>
)

export default McGonagaleInfo