import React from 'react'

const ScottInfo = (
    <>
    <p>
    Name:  SCOTT, Norman<br />
    Service: U.S. Navy<br />
    Conflict: WWII<br />
    Location and Date of Act: Iron Bottom Sound off Savo Island, Solomon Islands, 11-12 October & 12-13 November 1942<br />
    Entered Service: Indiana<br />
    Date of Birth: 10 August 1889<br />
    Place of Birth: Indianapolis, Indiana<br />
    Current Location: Buried/Lost at Sea. ‘In Memory Of’ Cenotaph at Crown Hill Cemetery, (Section 213) Indianapolis, Indiana (Died 13 November 1942).</p>
    <p>Citation: For extraordinary heroism and conspicuous intrepidity above and beyond the call of duty during action against enemy Japanese forces off Savo Island on the night of 11-12 October and again on the night of 12-13 November 1942.  In the earlier action, intercepting a Japanese Task Force intent upon storming our island positions and landing reinforcements at Guadalcanal, Rear Adm. Scott, with courageous skill and superb coordination of the units under his command, destroyed 8 hostile vessels and put the others to flight.  Again challenged, a month later, by the return of a stubborn and persistent foe, he led his force into a desperate battle against tremendous odds, directing close-range operations against the invading enemy until he himself was killed in the furious bombardment by their superior firepower.  On each of these occasions his dauntless initiative, inspiring leadership and judicious foresight in a crisis of grave responsibility contributed decisively to the rout of a powerful invasion fleet and to the consequent frustration of a formidable Japanese offensive.  He gallantly gave his life in the service of his country.</p>
    <p>Notes: After graduating from the Naval Academy in 1911, Scott was assigned to the USS Idaho where he served until July 1913.</p>
    </>
)

export default ScottInfo