import React from 'react'

const BoyingtonInfo = (
    <>
    <p>
    Name:  BOYINGTON, Gregory Pappy<br />
Service:  U.S. Marine Corps<br />
Conflict:  WWII<br />
Location and Date of Act:  Central Solomons Area, 12 September 1943 - 3 January 1944<br />
Entered Service:  Tacoma, Washington<br />
Date of Birth:  4 December 1912<br />
Place of Birth:  Coeur d'Alene, Idaho<br />
Current Location:  Arlington National Cemetery, (Section 7A, Grave 150), Arlington, Virginia  (Died 11 January 1988)</p>
<p>Citation:  For extraordinary heroism above and beyond the call of duty as Commanding Officer of Marine Fighting Squadron 214 in action against enemy Japanese forces in Central Solomons Area from 12 September 1943 to 3 January 1944.  Consistently outnumbered throughout successive hazardous flights over heavily defended hostile territory, Major Boyington struck at the enemy with daring and courageous persistence, leading his squadron into combat with devastating results to Japanese shipping, shore installations and aerial forces.  Resolute in his efforts to inflict crippling damage on the enemy, Major Boyington led a formation of twenty-four fighters over Kahili on 17 October and, persistently circling the airdrome where sixty hostile aircraft were grounded, boldly challenged the Japanese to send up planes.  Under his brilliant command, our fighters shot down twenty enemy craft in the ensuing action without the loss of a single ship.  A superb airman and determined fighter against overwhelming odds, Major Boyington personally destroyed 26 of the many Japanese planes shot down by his squadron and by his forceful leadership developed the combat readiness in his command which was a distinctive factor in the Allied aerial achievements in this vitally strategic area.</p>
<p>Notes:  Until he decided to apply for flight school, Boyington believed his name to be Gregory Hallenbeck.  Boyington was better known as Pappy than Greg and he had his name legally changed in 1961 when he decided to run for political office.</p>
    </>
)

export default BoyingtonInfo