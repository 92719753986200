import React from 'react'

const GreenInfo = (
    <>
    <p>
Name:  GREEN, John<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Lava Beds, California, 17 January 1873<br />
Entered Service:  Columbus Ohio<br />
Date of Birth:  20 November 1825<br />
Place of Birth:  Wurttemberg Germany<br />
Current Location:  Morris Hill Cemetery (Grave 1, Lot 105, Block E), Boise, Idaho  (Died 22 Nov 1908)</p>
<p>Citation:  In order to reassure his command, this officer, in the most fearless manner and exposed to very great danger, walked in front of the line; the command thus encouraged, advanced over the lava upon the Indians who were concealed among the rocks.</p>
<p>
Notes:  Green went by the name Johannes Grun before immigrating to the United States.  He was a career military man; there is even a report that he served in the German army prior to immigrating to the U.S.  Aside from the Indian Wars, Green also served in the Mexican War and Civil War.  He saw action in both the Nez Perce and Bannock Wars and in June 1878, he was assigned to Boise Barracks, Idaho.  Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/4thQtr1999.pdf" target="_blank" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/4thQtr1999.pdf</a>  (John Green has two Medals of Honor because he requested and received the 1904 updated style of the Medal, he was not a double recipient.)</p>
    </>
)

export default GreenInfo