import React from 'react'

const LongfellowInfo = (
    <>
    <p>Name:  LONGFELLOW, Richard Moses<br />
Service:  U.S. Army<br />
Conflict:  Philippine Insurrection<br />
Location and Date of Act:  Near San Isidro, Philippine Islands,16 May 1899<br />
Entered Service:  Mandan, North Dakota<br />
Date of Birth:  24 June 1867 <br />
Place of Birth:  Springfield, Illinois<br />
Current Location:  Normal Hill Cemetery (Div 4, Row 26, Lot 26, Grave 9), Lewiston, Idaho  (Died 17 May 1951)
</p>

<p>Citation:  With 21 other scouts charged across a burning bridge, under heavy fire, and completely routed 600 of the enemy who were entrenched in a strongly fortified position.</p>

<p>Notes: Records indicate that Longfellow’s award was issued on 4 April 1906.  In reality, he did not receive it until June 1909; it was lost in the mail.
</p>
    </>
)

export default LongfellowInfo