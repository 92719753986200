import React from 'react'

const GaujotInfo = (
    <>
    <p>
    Name:  GAUJOT, Julien Edmund <br />
Service:  U.S. Army<br />
Conflict:  Mexican Campaign<br />
Unit:  Troop K, 1st U.S. Cavalry<br />
Location and Date of Act:  Aqua Prieta, Mexico, 13 April 1911<br />
Entered Service:  Williamson, West Virginia<br />
Date of Birth:  22 October 1874<br />
Place of Birth: Keweenaw, Michigan <br />
Current Location:  Arlington National Cemetery (Section 6, Grave 8423-NH), Arlington, Virginia  (Died 7 April 1938 in Radford Virginia)</p>
<p>Citation:  Crossed the field of fire to obtain the permission of the rebel commander to receive the surrender of the surrounded forces of Mexican Federals and escort such forces, together with five Americans held as prisoners, to the American line. </p>
<p>Notes:  After his Medal of Honor action, Gaujot was assigned to Idaho’s Boise Barracks.  He is on record as being there as early as January 1911 but wasn’t assigned there until 21 August 1911.  He first served as Commander of Troop K, 1st US Cavalry and periodically served as Commander of the Barracks.  On 19 November 1912, he left Idaho for Washington D.C. to receive the Medal of Honor.  On or about 1 February 1913 he was transferred to California.</p>
    </>
)

export default GaujotInfo