import React from 'react'
import { Link } from 'gatsby'

const IdahoArmyNationalGuardToC = () => (
    <>
    <p>There are two components of the Idaho National Guard. They are the:</p>
    <p><Link to="/history#1891-current">Idaho Army National Guard (1891 - current)</Link></p>
    <p><Link to="/history#1946-current">Idaho Air National Guard (1946 - current)</Link></p>
    </>
)

export default IdahoArmyNationalGuardToC