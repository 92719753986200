import React from 'react'

const BrostromInfo = (
    <>
    <p>
    Name:  BROSTROM, Leonard Carl<br />
Service:  U.S. Army<br />
Conflict:  WWII<br />
Location and Date of Act:  Near Dagami, Leyte, Philippine Islands, 28 October 1944<br />
Entered Service:  Preston, Idaho<br />
Date of Birth:  23 November 1919<br />
Place of Birth:  Preston, Idaho<br />
Current Location:  Preston Cemetery District (NW Section, Block 1, Lot 18), Preston, Idaho  (KIA 20 Oct 1944)</p>
<p>Citation:  He was a rifleman with an assault platoon which ran into powerful resistance near Dagami, Leyte, Philippine Islands, on 28 October 1944.  From pillboxes, trenches, and spider holes so well-camouflaged that they could be detected at no more than 20 yards, the enemy poured machine-gun and rifle fire, causing severe casualties in the platoon.  Realizing that a key pillbox in the center of the strongpoint would have to be knocked out if the company were to advance, Private First Class Brostrom, without orders and completely ignoring his own safety, ran forward to attack the pillbox with grenades.  He immediately became the prime target for all the riflemen in the area, as he rushed to the rear of the pillbox and tossed grenades through the entrance.  Six enemy soldiers left a trench in a bayonet charge against the heroic American, but he killed one and drove the others off with rifle fire.  As he threw more grenades from his completely exposed position he was wounded several times in the abdomen and knocked to the ground.  Although suffering intense pain and rapidly weakening from loss of blood, he slowly rose to his feet and once more hurled his deadly missiles at the pillbox.  As he collapsed, the enemy began fleeing from the fortification and were killed by riflemen of his platoon.  Private First Class Brostrom died while being carried from the battlefield, but his intrepidity and unhesitating willingness to sacrifice himself in a one-man attack against overwhelming odds enabled his company to reorganize against attack and annihilate the entire enemy position.</p>
<p>Notes:  Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/2ndQtr2004.pdf" target="_blank " rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/2ndQtr2004.pdf</a></p>
    </>
)

export default BrostromInfo