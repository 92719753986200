import React from 'react'

const SijanInfo = (
    <>
    <p>Name:  SIJAN, Lance Peter<br />
Service:  U.S. AIR FORCE<br />
Conflict:  Vietnam<br />
Location and Date of Act:  North Vietnam, 9 November 1967<br />
Entered Service:  Milwaukee, Milwaukee County, Wisconsin<br />
Date of Birth:  13 April 1942<br />
Place of Birth:  Milwaukee, Wisconsin<br />
Current Location:  Buried in Arlington Park Cemetery (Block 26, Lot 68, Grave 7), Milwaukee Wisconsin  (Died 22 January 1968)</p>

<p>Citation:  While on a flight over North Vietnam, Captain Sijan ejected from his disabled aircraft and successfully evaded capture for more than six weeks.  During this time, he was seriously injured and suffered from shock and extreme weight loss due to lack of food.  After being captured by North Vietnamese soldiers, Captain Sijan was taken to a holding point for subsequent transfer to a prisoner of war camp.  In his emaciated and crippled condition, he overpowered one of his guards and crawled into the jungle, only to be recaptured after several hours.  He was then transferred to another prison camp where he was kept in solitary confinement and interrogated at length.  During interrogation, he was severely tortured; however, he did not divulge any information to his captors.  Captain Sijan lapsed into delirium and was placed in the care of another prisoner.  During his intermittent periods of consciousness until his death, he never complained of his physical condition and, on several occasions, spoke of future escape attempts.  Captain Sijan’s extraordinary heroism and intrepidity above and beyond the call of duty at the cost of his life are in keeping with the highest traditions of the U.S. Air Force and reflect great credit upon himself and the U.S. Armed Forces.
</p>

<p>Notes:  Although Sijan has no known ties to Idaho, he was a member of the 366th TFW/480th TFS while the wing was stationed at Da Nang AB, Vietnam. The 366th was transferred to Mountain Home Air Force Base, Idaho, from Takhli, Thailand on 31 October 1972.  The preceding was provided by the 366th Wing History Office, Mountain Home, Idaho AFB.</p>
    </>
)

export default SijanInfo