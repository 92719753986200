import React from 'react'

const WilkensInfo = (
    <>
    <p>Name:  WILKENS, Henry<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Little Muddy Creek, Montana, Camas Meadows, Idaho, 7 May 1877 and 20 August 1877 respectively.<br />
Entered Service:  New York <br />
Date of Birth:  Circa 1833<br />
Place of Birth:  Germany<br />
Current Location:  New York, Brooklyn Cypress Hill National Cemetery (2-5325)  (Died 2 August 1895)</p>

<p>Citation:  Bravery in actions with Indians. </p>

<p>Notes:  Wilkens’ date of birth has been recorded as 1855 but this is impossible.  He was a veteran of the Civil War and in fact attained the rank of First Lieutenant in 1864.  Wilkens is sometimes listed as accredited to Pennsylvania but this too is in error.  The Army issued a Records correction in 2015.
</p>
    </>
)

export default WilkensInfo