import React from 'react'

const NeibaurInfo = (
    <>
    <p>Name:  NEIBAUR, Thomas Croft<br />
Service:  U.S. Army<br />
Conflict:  WWI<br />
Location and Date of Act:  Near Landres-et-St. Georges, France, 16 October 1918<br />
Entered Service:  Sugar City, Idaho<br />
Date of Birth:  17 May 1898<br />
Place of Birth:  Sharon, Idaho<br />
Current Location:  Sugar City Cemetery (Block 2, Lot 38, Grave 3), Sugar City Idaho  (Died 23 Dec 1942)</p>

<p>Citation:  On the afternoon of 16 October 1918, when the Cote-de-Chatillion had just been gained after bitter fighting and the summit of the strong bulwark in the Kriemhilde Stellung was being organized, Private Neibaur was sent out on patrol with his automatic rifle squad to enfilade enemy machine-gun nests.  As he gained the ridge, he set up his automatic rifle and was directly thereafter wounded in both legs by fire from a hostile machine-gun on his flank.  The advance wave of the enemy troops, counterattacking, had about gained the ridge, and although practically cut off and surrounded, the remainder of his detachment being killed or wounded, this gallant soldier kept his automatic rifle in operation to such effect that by his own efforts and by fire from the skirmish line of his company, at least one hundred yards in his rear, the attack was checked.  The enemy wave being halted and lying prone, four of the enemy attacked Private Neibaur at close quarters.  These he killed.  He then moved alone among the enemy lying on the ground about him, in the midst of the fire from his own lines, and by coolness and gallantry captured eleven prisoners at the point of his pistol and, although painfully wounded, brought them back to our lines.  The counterattack in full force was arrested to a large extent by the single efforts of this soldier, whose heroic exploits took place against the skyline in full view of his entire battalion.</p>

<p>Notes:  Prior to deploying overseas, Neibaur was a member of Co M, 2nd Idaho Infantry, Idaho National Guard.   History misrecorded Neibaur’s overseas unit as the 107th instead of the 167th Infantry and his presentation information was unknown.  The Army issued a Records correction in April 2009.  Some sources recorded that Neibaur was promoted to Private First Class before being discharged but to date; no substantiating documentation has been located.  Only four WWI Medals of Honor were approved before the armistice, Neibaur’s was one of the four.</p>
    </>
)

export default NeibaurInfo