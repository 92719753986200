import React from 'react'

const KephartInfo = (
    <>
    <p>Name:  KEPHART, James<br />
Service:  U.S. Army<br />
Conflict:  Civil War<br />
Location and Date of Act:  Vicksburg Mississippi, 19 May 1863<br />
Entered Service:  Dubuque, Iowa<br />
Date of Birth:  22 April 1842<br />
Place of Birth:  Venango County, Pennsylvania<br />
Current Location:  Elmwood Cemetery (Grave 8, Lot B, Block 12), Gooding, Idaho  (Died 27 April 1932)</p>

<p>Citation:   At the assault on the Confederate works at Vicksburg, Miss., May 19, 1863, Mr. Kephart, then a private of Company C, 1st Battalion, 13th U.S. Infantry, voluntarily, and at the risk of his life, when the Battalion was about to retreat, returned, and under a severe fire from the enemy, aided and assisted to the rear an officer who had been severely wounded and left on the field, thereby saving him from capture.</p>

<p>Notes: History recorded Kephart’s date of death as 17 April 1932.  It was actually 27 April 1932.  History also abbreviated Kephart’s citation and unit. Profiled in Idaho Military History Museum newsletter <a href="https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/4thQtr2003.pdf" target="_blank" rel="noopener noreferrer">https://museum.mil.idaho.gov/wp-content/uploads/sites/61/2019/09/4thQtr2003.pdf</a>
</p>
    </>
)

export default KephartInfo