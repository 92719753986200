import React from 'react'

const ClarkInfo = (
    <>
    <p>
    Name:  CLARK, Wilfred<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Big Hole, Montana, Camas Meadows, Idaho 9 and 20 August 1877 respectively.<br />
Entered Service:  Philadelphia, Pennsylvania<br />
Date of Birth:  1841<br />
Place of Birth:  Philadelphia, Pennsylvania<br />
Current Location:  Lost to History, Clark's last known location was Cascade Montana.</p>
<p>Citation:  Conspicuous gallantry; especial skill as sharp-shooter.</p>
    </>
)

export default ClarkInfo