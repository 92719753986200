import React from 'react'

const LeonardInfo = (
    <>
    <p>Name:  LEONARD, Patrick Thomas<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Fort Hartsuff (Grace Creek), Nebraska, 28 April 1876<br />
Entered Service:  New York, New York  <br />
Date of Birth:  1828<br />
Place of Birth:  Clare County, Ireland<br />
Current Location:  Mount Calvary Cemetery (Section 2 SE Corner), Lansing, Kansas  (Died 1 March 1905).
</p>

<p>Citation:  Gallantry in charge on hostile Sioux.  </p>

<p>Notes:  Patrick T. Leonard was stationed at Camp Three Forks, Idaho Territory during the late 1860's/early 1870's.  While there, he had a short break in service prior to re-enlisting there and going on to earn the Medal of Honor in Nebraska.  As such, he could technically be accredited to Idaho.  Originally believed to be a double recipient, it wasn't until 1984 that it was learned there were in fact two Indian Campaigns recipients with the name of Patrick Leonard.
</p>
    </>
)

export default LeonardInfo