import React from 'react'

const ConawayInfo = (
    <>
    <p>
    Name:  CONAWAY, John Wesley<br />
Service:  U.S. Army<br />
Conflict:  Civil War<br />
Location and Date of Act:  Vicksburg, Mississippi, 22 May 1863<br />
Entered Service:  Rising Sun, Indiana<br />
Date of Birth:  19 September 1843<br />
Place of Birth:  Dearborn County, Indiana<br />
Current Location:  Evergreen Cemetery (Lot 6, Block 30), Post Falls, Idaho  (Died 21 Nov 1913)</p>
<p>Citation:  Gallantry in the charge of the “volunteer storming party.”</p>
<p>Notes:  Conaway’s enlistment location has been incorrectly recorded.  His enlistment contract states he enlisted in Rising Sun.</p>
    </>
)

export default ConawayInfo