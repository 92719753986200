import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Nav } from 'react-bootstrap'
import Scrollspy from 'react-scrollspy'

const HistorySidebar = () => {
  const [key, setKey] = useState("")

  return (
  
    <div className="card-box" id="history-menu">
        <h1>History Menu</h1>
        <Nav
        style={{display: 'flex',
                flexDirection: 'column'}} 
           activeKey={key}
           onSelect={k => setKey(k)}
          >
            <Scrollspy items={ ['uss-idaho-ships', 'idaho-naval-history', 'idaho-national-guard', 'idaho-army-history', 'idaho-air-force-history', 'idaho-marine-corps-history', 'idaho-submariners-on-eternal-patrol', 'idaho-congressional-medal-of-honor-recipients', 'us-navy-ships-named-for-idaho', 'native-american-warriors', 'submarine-documentaries-books', 'idaho-military-history-documentaries-books'] } 
              currentClassName="isCurrent" 
              style={{
                paddingLeft: 0
            }}>
            <Nav.Item>
        <Nav.Link
                href="/history#uss-idaho-ships"
                eventKey="5.1"
                className="dropdown-item nav-link historySidebarMenu"
              >
                USS IDAHO Ships
            </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="5.2"
                  className="dropdown-item nav-link historySidebarMenu"
                  href="/history#idaho-naval-history"
                >
                  Idaho Naval History Brief Summaries
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.3"
                  href="/history#idaho-national-guard"
                >
                  Idaho National Guard
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.4"
                  href="/history#idaho-army-history"
                >
                  Idaho Army History
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.5"
                  href="/history#idaho-air-force-history"
                >
                  Idaho Air Force History
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.6"
                  href="/history#idaho-marine-corps-history"
                >
                  Idaho Marine Corps History
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.7"
                  href="/history#idaho-submariners-on-eternal-patrol"
                >
                  Idaho Submariners on Eternal Patrol
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.8"
                  href="/history#idaho-congressional-medal-of-honor-recipients"
                >
                  Idaho Congressional Medal of Honor Recipients
                </Nav.Link>
              </Nav.Item>
              
              <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.8"
                  href="/history#us-navy-ships-named-for-idaho"
                >
                  US Navy Ships Named for Idaho Cities, Counties, and Icons
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.9"
                  href="/history#native-american-warriors"
                >
                  Native American Warriors
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.10"
                  href="/history#submarine-documentaries-books"
                >
                  Submarine Documentaries &amp; Books
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.11"
                  href="/history#idaho-military-history-documentaries-books"
                >
                  Idaho Military History Documentaries &amp; Books
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                
                  className="dropdown-item nav-link historySidebarMenu"
                  eventKey="5.12"
                  href="/history#about-the-historian"
                >
                  About the Historian
                </Nav.Link>
              </Nav.Item>
              </Scrollspy>
        </Nav>
        
    </div>
)
}

export default HistorySidebar