import React from 'react'

const CallaghanInfo = (
    <>
    <p>
    Name: CALLAGHAN, Daniel Judson<br />
    Service: U.S. Navy<br />
    Conflict: WWII<br />
    Location and Date of Act: Iron Bottom Sound off Savo Island, Solomon Islands, 12-13 November 1942<br />
    Entered Service: Oakland, California<br />
    Date of Birth: 26 July 1890<br />
    Place of Birth: San Francisco, California<br />
    Current Location: Buried at Sea.  ‘In Memory Of’ Cenotaph at Holy Cross Cemetery (Section B at base of Navy Flag), Colma, California  (Died 13 November 1942).</p>
    <p>Citation: For extraordinary heroism and conspicuous intrepidity above and beyond the call of duty during action against enemy Japanese forces off Savo Island on the night of 12-13 November 1942.  Although out-balanced in strength and numbers by a desperate and determined enemy, Rear Adm. Callaghan, with ingenious tactical skill and superb coordination of the units under his command, led his forces into battle against tremendous odds, thereby contributing decisively to the rout of a powerful invasion fleet, and to the consequent frustration of a formidable Japanese offensive.  While faithfully directing close-range operations in the face of furious bombardment by superior enemy fire power, he was killed on the bridge of his flagship.  His courageous initiative, inspiring leadership, and judicious foresight in a crisis of grave responsibility were in keeping with the finest traditions of the U.S. Naval Service.  He gallantly gave his life in the defense of his country.</p>
    <p>Notes: Beginning in late 1920, Callaghan served for three years as assistant fire control officer of the USS Idaho.</p>
    </>
)

export default CallaghanInfo