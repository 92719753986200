import React from "react"
import { Modal, Row, Col } from "react-bootstrap"

function IdahoMedalofHonorModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Row>
                    <Col>
                        <div>
                            <h3>{props.recepientName}</h3>
                        </div>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                {props.modalContent}
            </Modal.Body>
      </Modal>
    )
}

export default IdahoMedalofHonorModal