import React from 'react'

const JacksonJInfo = (
    <>
    <p>Name:  JACKSON, James<br />
Service:  U.S. Army<br />
Conflict:  Indian Campaigns<br />
Location and Date of Act:  Camas Meadows, Idaho, 20 August 1877<br />
Entered Service:  Dubuque, Iowa<br />
Date of Birth:  21 November 1833<br />
Place of Birth:  New Jersey<br />
Current Location:  River View Cemetery, (Section 15, Lot 174, Grave 4), Portland, Oregon  (Died 21 October 1916)</p>
<p>Citation:  Dismounted from his horse in the face of a heavy fire from pursuing Indians and, with the assistance of one or two of the men of his command, secured to a place of safety the body of his trumpeter, who had been shot and killed.</p>
<p>Notes:  Jackson is often listed as entering the service from New Jersey.  This is incorrect.  He was living in Iowa when the Civil War broke out and he was given a recruiting commission to recruit men for the Twelfth Regiment of Iowa Volunteers.  The Army issued a Records correction in 2015.  Jackson also served at Fort Coeur d’ Alene, Idaho from November 1882 to June 1884. </p>
    </>
)

export default JacksonJInfo