import React from 'react'

const BushInfo = (
    <>
    <p>
    Name:  BUSH, Robert Eugene<br />
Service:  U.S. Navy Reserve <br />
Conflict:  WWII<br />
Location and Date of Act:  Okinawa Jima, Ryukyu Islands, 2 May 1945<br />
Entered Service:  Seattle, Washington  <br />
Date of Birth:  4 October 1926<br />
Place of Birth:  Tacoma, Washington<br />
Current Location:  Fern Hill Cemetery (6th Edition, Block 1, Lot 24, South half of the Southwest Corner), Menlo, Washington  (Died 8 November 2005).</p>
<p>Citation:  For conspicuous gallantry and intrepidity at the risk of his life above and beyond the call of duty while serving as Medical Corpsman with a rifle company, in action against enemy Japanese forces on Okinawa Jima, Ryukyu Islands, 2 May 1945.  Fearlessly braving the fury of artillery, mortar, and machine-gun fire from strongly entrenched hostile positions, Bush constantly and unhesitatingly moved from one casualty to another to attend the wounded falling under the enemy’s murderous barrages.  As the attack passed over a ridge top, Bush was advancing to administer blood plasma to a marine officer lying wounded on the skyline when the Japanese launched a savage counterattack.  In this perilously exposed position, he resolutely maintained the flow of life-giving plasma.  With the bottle held high in one hand, Bush drew his pistol with the other and fired into the enemy’s ranks until his ammunition was expended.  Quickly seizing a discarded carbine, he trained his fire on the Japanese charging point-blank over the hill, accounting for six of the enemy despite his own serious wounds and the loss of one eye suffered during his desperate battle in defense of the helpless man.  With the hostile force finally routed, he calmly disregarded his own critical condition to complete his mission, valiantly refusing medical treatment for himself until his officer patient had been evacuated, and collapsing only after attempting to walk to the battle aid station.  His daring initiative, great personal valor, and heroic spirit of self-sacrifice in service of others reflect great credit upon Bush and enhance the finest traditions of the U.S. Naval Service.</p>
<p>Notes:  Bush attended Basic Training and Naval Medical Corpsman training at Farragut, Idaho.  Bush was a Navy medical corpsman during the Battle of Okinawa and at the age of 18, was the youngest Corpsman to receive the Medal of Honor during World War II.
As part of the Portraits of Valor book, Mr. Bush gave an interview about his MoH action. Click here to watch it: <a href="https://www.youtube.com/watch?v=yVN57F032NM" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=yVN57F032NM</a></p>
    </>
)

export default BushInfo