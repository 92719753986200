import React from 'react'

const KaneInfo = (
    <>
    <p>Name:  KANE, John Riley<br />
Service:  U.S. Army Air Corps<br />
Conflict:  WWII<br />
Unit:  98th Bombardment Group, 9th Air Force<br />
Location and Date of Act:  Ploetsi Raid, Rumania, 1 August 1943<br />
Entered Service:  Shreveport, Caddo County, Louisiana<br />
Date of Birth:  5 January 1907<br />
Place of Birth:  McGregor, Texas.<br />
Current Location:  Arlington National Cemetery (Section 7A, Grave 47)  (Died 29 May 1996)</p>

<p>Citation:  For conspicuous gallantry in action and intrepidity at the risk of his life above and beyond the call of duty on 1 August 1943.  On this date he led the third element of heavy bombardment aircraft in a mass low-level bombing attack against the vitally important enemy target of the Ploesti oil refineries.  En route to the target, which necessitated a round-trip flight of over 2,400 miles, Colonel Kane’s element became separated from the leading portion of the massed formation in avoiding dense and dangerous cumulous cloud conditions over mountainous terrain.  Rather than turn back from such a vital mission he elected to proceed to his target.  Upon arrival at the target area it was discovered that another group had apparently missed its target and had previously attacked and damaged the target assigned to Colonel Kane’s element.  Despite the thoroughly warned defenses, the intensive antiaircraft fire, enemy fighter airplanes, extreme hazards on a low-level attack of exploding delayed-action bombs from the previous element, of oil fire and explosions and dense smoke over the target area, Colonel Kane elected to lead his formation into the attack.  By his gallant courage, brilliant leadership, and superior flying skill, he and the formation under his command successfully attacked this vast refinery so essential to our enemies’ war effort.  Through his conspicuous gallantry in this most hazardous action against the enemy, and by his intrepidity at the risk of his life above and beyond the call of duty, Colonel Kane personally contributed vitally to the success of this daring mission and thereby rendered most distinguished service in the furtherance of the defeat of our enemies.</p>

<p>Notes:  After his heroic action at Ploesti, Kane returned to the United States in February 1944, as Base Commander of Gowen Field, Boise, Idaho where he served from 9 February 1944 to 25 December 1944.  In November 1951 he was assigned to Mountain Home Air Force Base, Idaho, where he commanded the 580th Air Resupply and Communications Wing.  Kane was stationed at Mountain Home until September 1952 when he and the Wing were transferred to Wheelus AB in Libya.
</p>
    </>
)

export default KaneInfo